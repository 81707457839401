import { Fragment, useEffect, useState } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import Card from "../../components/Card";
import MainTemplate from "../../components/MainTemplate";
import Table from "../../components/Table";
import { Text, Bold, ButtonCustom, ButtonCustomSecondary } from "./styled";
import api from "../../services/api";
import { snackbarActive } from "../../store/modules/snackbar/action";
import Filters from "./components/Filters";
import TableContracts from "./components/TableContracts";
import ModalHistoricAlcada from "../../components/modals/ModalHistoricAlcada";
import ModalDeleteAlcada from "./components/ModalDeleteAlcada";
import ModalAlcadaProtheus from "./components/ModalAlcadaProtheus";

export default function Alcadas() {
  const { control, watch } = useForm();

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalSearched, setTotalSearched] = useState(0);
  const [alcadas, setAlcadas] = useState([]);
  const [alcadasOpened, setAlcadasOpened] = useState([]);
  const [alcadaSelected, setAlcadaSelected] = useState(null);

  const [loadingTable, setLoadingTable] = useState(false);

  const [selectedsIdsProtheus, setSelectedsIdsProtheus] = useState([]);

  const [openModalHistoric, setOpenModalHistoric] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalAlcadaProtheus, setOpenModalAlcadaProtheus] = useState(false);

  const apiGetAlcadas = (activePage = 1) => {
    setLoadingTable(true);

    api
      .get("/alcadas", {
        params: {
          page: activePage || page,
          vigencia: watch("dateMonth") ?? null,
          status_id: watch("status") ?? null,
          alcada_id: watch("alcadaId") ?? null,
        },
      })
      .then((response) => {
        setAlcadas(response.data.data);
        setLastPage(response.data.last_page);
        setTotalItems(response.data.total);
        setTotalSearched(response.data.to);
      })
      .catch((error) => {
        dispatch(snackbarActive("error", "Erro ao buscar alçadas"));
      })
      .finally(() => {
        setLoadingTable(false);
      });
  };

  const handlePagination = (page) => {
    setPage(page);
    apiGetAlcadas(page);
    setAlcadasOpened([]);
  };

  const handleCleanFilters = () => {
    setPage(1);
    apiGetAlcadas(1);
    setAlcadasOpened([]);
  };

  const handleOpenAlcada = (id) => {
    if (alcadasOpened.includes(id)) {
      setAlcadasOpened(alcadasOpened.filter((item) => item !== id));
    } else {
      setAlcadasOpened([...alcadasOpened, id]);
    }
  };

  const handleBorderBottomTable = (id) => {
    if (alcadasOpened.includes(id)) {
      return { borderBottom: "none" };
    }

    return null;
  };

  useEffect(() => {
    handlePagination(1);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainTemplate title="Alçadas">
        <Grid pt={1} container alignItems="stretch" spacing={1}>
          <Grid item md={10} sm={9} xs={12}>
            <Filters
              control={control}
              handleCleanFilters={handleCleanFilters}
              handlePagination={handlePagination}
            />
          </Grid>

          <Grid item md={2} sm={3} xs={12}>
            <Card>
              <Text>Total de registros</Text>

              <Bold>
                {totalItems || 0}
                <span style={{ fontSize: "13px" }}>Alçada(s)</span>
              </Bold>

              <Text style={{ paddingTop: "36px" }}>Encontrados</Text>
              <Bold>
                {totalSearched || 0}
                <span style={{ fontSize: "13px" }}>Alçada(s)</span>
              </Bold>
            </Card>
          </Grid>
        </Grid>

        <Grid pt={1} container spacing={1}>
          <Grid item md={12} xs={12}>
            <Card style={{ padding: 0 }}>
              <Table
                header={[
                  "",
                  "ID",
                  "Valor total",
                  "Qtd. Contratos",
                  "Status",
                  "Próximo aprovador",
                  "Criado por",
                  "Criado em",
                  "Ações",
                ]}
                loading={loadingTable}
                scrollTopActive={false}
                height="500px"
              >
                {alcadas?.length > 0 &&
                  alcadas?.map((alcada) => (
                    <Fragment key={alcada.id}>
                      <tr>
                        <td style={handleBorderBottomTable(alcada.id)}>
                          <Box
                            sx={{
                              display: "flex",
                            }}
                          >
                            <IconButton
                              title="Visualizar contratos dessa alçada"
                              onClick={() => handleOpenAlcada(alcada.id)}
                            >
                              <KeyboardArrowDownIcon
                                sx={{
                                  transition: "rotate 150ms ease-out",
                                  rotate: alcadasOpened.includes(alcada.id)
                                    ? "-180deg"
                                    : "unset",
                                }}
                              />
                            </IconButton>
                          </Box>
                        </td>
                        <td style={handleBorderBottomTable(alcada.id)}>
                          {alcada.id}
                        </td>
                        <td style={handleBorderBottomTable(alcada.id)}>
                          {alcada.valor_total}
                        </td>
                        <td style={handleBorderBottomTable(alcada.id)}>
                          {alcada.quantidade_contratos}
                        </td>
                        <td style={handleBorderBottomTable(alcada.id)}>
                          {alcada.status}
                        </td>
                        <td style={handleBorderBottomTable(alcada.id)}>
                          {alcada.proximo_aprovador}
                        </td>
                        <td style={handleBorderBottomTable(alcada.id)}>
                          {alcada.criado_por}
                        </td>
                        <td style={handleBorderBottomTable(alcada.id)}>
                          {alcada.criado_em}
                        </td>
                        <td
                          style={{
                            ...handleBorderBottomTable(alcada.id),
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <ButtonCustom
                            onClick={() => {
                              setOpenModalHistoric(true);
                              setAlcadaSelected(alcada);
                            }}
                          >
                            Histórico
                          </ButtonCustom>

                          {alcada.pode_enviar_protheus ? (
                            <ButtonCustomSecondary
                              onClick={() => {
                                setOpenModalAlcadaProtheus(true);
                                setAlcadaSelected(alcada);
                              }}
                            >
                              Enviar Protheus
                            </ButtonCustomSecondary>
                          ) : null}

                          {alcada.pode_excluir ? (
                            <ButtonCustom
                              sx={{
                                color: "#d90e0e!important",
                                border: "1px solid #d90e0e!important",
                                backgroundColor: "transparent!important",
                                "&:hover": {
                                  backgroundColor: "#d90e0e!important",
                                  color: "#FFF!important",
                                },
                              }}
                              onClick={() => {
                                setOpenModalDelete(true);
                                setAlcadaSelected(alcada);
                              }}
                            >
                              Excluir alçada
                            </ButtonCustom>
                          ) : null}
                        </td>
                      </tr>

                      {alcadasOpened?.includes(alcada.id) ? (
                        <tr>
                          <td colSpan={10}>
                            <TableContracts
                              alcadaId={alcada.id}
                              selectedsIdsProtheus={selectedsIdsProtheus}
                              setSelectedsIdsProtheus={setSelectedsIdsProtheus}
                            />
                          </td>
                        </tr>
                      ) : null}
                    </Fragment>
                  ))}
              </Table>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                  paddingBottom: 20,
                }}
              >
                <Pagination
                  count={lastPage}
                  page={page}
                  onChange={(event, value) => handlePagination(value)}
                />
              </div>
            </Card>
          </Grid>
        </Grid>
      </MainTemplate>

      <ModalHistoricAlcada
        open={openModalHistoric}
        setOpen={setOpenModalHistoric}
        alcadaId={alcadaSelected?.id}
      />

      <ModalDeleteAlcada
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        alcada={alcadaSelected}
        apiGetAlcadas={apiGetAlcadas}
      />

      <ModalAlcadaProtheus
        open={openModalAlcadaProtheus}
        setOpen={setOpenModalAlcadaProtheus}
        alcadaId={alcadaSelected?.id}
      />
    </>
  );
}
