import styled from "styled-components";
import { Button } from "@mui/material";

export const Btn = styled(Button)` 
    background-color: white;
    border: 2px solid var(--main-color)!important;
    font-weight: 600!important;
    font-size: 14px!important;
    line-height: 25px!important;
    color: var(--main-color)!important;
    border-radius: 34px!important;
    padding: .7em 1.5em!important;
    text-transform: none!important;

    span{
        padding-right: .2em;
    }

    :hover{
        opacity: .6;
    }
`;