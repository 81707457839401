import { useState } from "react";
import { Box, Fab, Typography } from "@mui/material";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import CheckIcon from "@mui/icons-material/Check";

import { Footer } from "./styled";
import ModalBatchProtheus from "./ModalBatchProtheus";
import ModalCreateAlcada from "./ModalCreateAlcada";

export default function ActionsFooter({
  selectedsIdsProtheus,
  setSelectedsIdsProtheus,
  getDataApiContracts,
}) {
  const [openModalBatchProtheus, setOpenModalBatchProtheus] = useState(false);
  const [openModalCreateAlcada, setOpenModalCreateAlcada] = useState(false);

  if (selectedsIdsProtheus?.length === 0) {
    return null;
  }

  return (
    <>
      <Footer>
        <Typography
          sx={{
            gap: "4px",
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            fontSize: "14px",
            fontWeight: "600",
            textAlign: "right",
            color: "var(--text-color)",
          }}
        >
          {selectedsIdsProtheus?.length} contrato
          {selectedsIdsProtheus?.length > 1 ? "s" : ""} selecionado
          {selectedsIdsProtheus?.length > 1 ? "s" : ""}
          <Typography
            component={"span"}
            onClick={() => setSelectedsIdsProtheus([])}
            sx={{
              fontSize: "10px",
              fontWeight: "700",
              textTransform: "uppercase",
              display: "flex",
              textAlign: "right",
              color: "var(--text-color)",
              textDecoration: "underline",
              width: "max-content",
              cursor: "pointer",
              "&:hover": {
                opacity: ".8",
              },
            }}
          >
            Limpar seleção
          </Typography>
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Fab
            onClick={() => setOpenModalBatchProtheus(true)}
            sx={{
              textTransform: "none",
              backgroundColor: "#DA45F8",
              color: "#FFF",
            }}
            variant="extended"
            color="primary"
            aria-label="add"
          >
            Enviar Protheus
            <CheckIcon sx={{ flexShrink: "0", paddingLeft: "8px" }} />
          </Fab>

          <Fab
            onClick={() => setOpenModalCreateAlcada(true)}
            sx={{
              textTransform: "none",
              backgroundColor: "var(a--secondary-color)",
              color: "#FFF",
            }}
            variant="extended"
            color="primary"
            aria-label="add"
          >
            Criar alçada
            <FactCheckIcon sx={{ flexShrink: "0", paddingLeft: "8px" }} />
          </Fab>
        </Box>
      </Footer>

      <ModalBatchProtheus
        open={openModalBatchProtheus}
        setOpen={setOpenModalBatchProtheus}
        selectedsIdsProtheus={selectedsIdsProtheus}
        getDataApiContracts={getDataApiContracts}
        setSelectedsIdsProtheus={setSelectedsIdsProtheus}
      />

      <ModalCreateAlcada
        open={openModalCreateAlcada}
        setOpen={setOpenModalCreateAlcada}
        contractsIds={selectedsIdsProtheus}
        setSelectedContracts={setSelectedsIdsProtheus}
      />
    </>
  );
}
