import styled from "styled-components";

export const Area = styled.header`
  height: var(--header-height);
`;

export const Fixed = styled.div`
  height: var(--header-height);
  box-sizing: border-box;
  width: 100%;
  background: white;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 1em;
  z-index: 30;
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: 1024px) {
    .buttonMenuMinimized {
      display: none;
    }
  }
`;

export const LogoArea = styled.div`
  margin-left: 0.3em;

  @media (max-width: 1024px) {
    margin-left: 0;
  }
`;

export const InfoArea = styled.div`
  display: flex;
  align-items: center;

  img {
    border-radius: 50px;
  }

  svg {
    color: #636363;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const AreaMenu = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;

  h6 {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #636363;
  }

  :hover {
    opacity: 0.6;
  }
`;

export const MobileArea = styled.div`
  display: none;
  align-self: center;

  svg {
    font-size: 30px;
    color: grey;
  }

  @media (max-width: 1024px) {
    display: flex;
  }
`;
