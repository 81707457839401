import { Box } from "@mui/material";
import { useSelector } from "react-redux";

import Container from "../Container";
import Header from "../Header";
import SideMenu from "../SideMenu";
import { Main } from "./styled";

export default function MainTemplate(props) {
  const isMenuMinimized = useSelector((state) => state.configs);

  return (
    <>
      <Header />

      <Main>
        <Box
          sx={{
            height: "100%",
            display: { lg: "block", md: "none", xs: "none" },
            width: isMenuMinimized
              ? "var(--sidemenu-width-minimized)"
              : "var(--sidemenu-width)",
          }}
        >
          <SideMenu isMenuMinimized={isMenuMinimized} />
        </Box>

        <Box
          sx={{
            height: "100%",
            display: "block",
            width: {
              lg: isMenuMinimized
                ? "calc(100% - var(--sidemenu-width-minimized))"
                : "calc(100% - var(--sidemenu-width))",
              md: "100%",
              xs: "100%",
            },
          }}
        >
          <Container title={props.title}>{props.children}</Container>
        </Box>
      </Main>
    </>
  );
}
