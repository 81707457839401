import { useEffect } from "react";

import Card from "../../components/Card";
import MainTemplate from "../../components/MainTemplate";

export default function ParamsSystem() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainTemplate title="Parâmetros do sistema">
      <Card></Card>
    </MainTemplate>
  );
}
