import styled from "styled-components";

export const Area = styled.section`
  padding: 1em;
  background-color: #f7f7f7;
  height: 100%;
  min-height: 100vh;

  @media (max-width: 468px) {
    padding: 0;
    margin-bottom: 7em;
  }
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 26px;
  line-height: 24px;
  color: var(--text-color);
  margin-top: 1em;
  margin-bottom: 0.5em;

  @media (max-width: 468px) {
    margin-top: 0;
    font-size: 22px;
    padding-top: 1.5em;
    padding-left: 0.5em;
  }
`;
