import styled from "styled-components";

export const BtnAction = styled.span` 
    background-color: #DA45F8;
    color: white;
    border-radius: 50px;
    padding: .5em 1em;
    cursor: pointer;
    transition: all .3s ease;
    display: inline-flex;
    width: max-content;

    :hover{
        opacity: .6;
    }

    :active{ 
        outline: 1px solid lightgrey;
    }
`;

export const ModalButtonArea = styled.div` 
    gap: 10px;
    display: flex;
    justify-content: flex-end;
    margin-top: 1.7em;

    button{
        background: grey;
        border: 0;
        color: white;
        font-size: 14px;
        border-radius: 6px;
        padding: .8em 1.6em;

        :hover{
            opacity: .6;
        }

        :active{
            outline: 3px solid lightgrey;
        }

        :first-child{
            background: var(--main-color);
        }
    }
`;

export const MessageError = styled.span` 
    color: #d32f2f;
    font-size: 12px;
`;

export const TitleList = styled.h1` 
    @media (max-width: 468px){
        padding-left: .5em;
    }
`