import styled from "styled-components";

export const Title = styled.h5`
  font-size: 20px;
  color: var(--text-color);
  margin: 0;
`;

export const Description = styled.p`
  color: grey;
  font-size: 14px;
  margin: 0;
  line-height: 1.5em;
  padding-top: 0.5em;
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 18px;
  width: max-content;
`;

export const ModalButtonArea = styled.div`
  gap: 10px;
  display: flex;
  justify-content: flex-end;
  margin-top: 1.7em;

  button {
    background: grey;
    border: 0;
    color: white;
    font-size: 14px;
    border-radius: 6px;
    padding: 0.8em 1.6em;

    :hover {
      opacity: 0.6;
    }

    :active {
      outline: 3px solid lightgrey;
    }

    :first-child {
      background: var(--main-color);
    }
  }
`;
