import { useDispatch } from "react-redux";

import { loadingFullActive } from "../../../../../store/modules/loadingFull/action";
import api from "../../../../../services/api";
import { snackbarActive } from "../../../../../store/modules/snackbar/action";
import MainModal from "../../../../../components/MainModal";
import { ModalButtonArea } from "./styled";

export default function ModalBatchProtheus({
  open,
  setOpen,
  selectedsIdsProtheus,
  getDataApiContracts,
  setSelectedsIdsProtheus,
}) {
  const dispatch = useDispatch();

  const apiArrayProtheus = () => {
    dispatch(loadingFullActive(true));

    setOpen(false);

    const arrayIdsContracts = selectedsIdsProtheus.map((item) => {
      return item.id;
    });

    api
      .post(`/contracts/protheus/batch`, { contracts_ids: arrayIdsContracts })
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message ||
              "Contratos enviados para o Protheus com sucesso!",
          })
        );

        getDataApiContracts();
        setSelectedsIdsProtheus([]);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  return (
    <MainModal width={450} open={open} setOpen={setOpen}>
      <h2 style={{ margin: 0 }}>Confirmação</h2>

      <p>Deseja realmente enviar esses contratos para o Protheus?</p>

      <div style={{ overflowX: "scroll", maxHeight: "300px" }}>
        {selectedsIdsProtheus?.map((item) => (
          <p
            key={item.id}
            style={{
              fontSize: "14px",
              background: "#c6c6c670",
              margin: ".5em 0",
              borderRadius: "50px",
              padding: ".5em 1em",
              width: "fit-content",
            }}
          >
            {item.label}
          </p>
        ))}
      </div>

      <ModalButtonArea>
        <button onClick={() => apiArrayProtheus()}>Enviar contrato(s)</button>

        <button onClick={() => setOpen(false)}>Cancelar</button>
      </ModalButtonArea>
    </MainModal>
  );
}
