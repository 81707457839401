import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NumberFormat from "react-number-format";
import { Grid, MenuItem } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { Step, GridBorder, ButtonArea } from "./styled";
import api from "../../../../services/api";
import MessageError from "../../../../components/MessageError";
import Label from "../../../../components/Label";
import SelectInput from "../../../../components/SelectInput";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";

export default function StepValoresVigencia({
  step,
  control,
  errors,
  setValue,
  enableEdit,
  setEnableEdit,
  watch,
  handleSubmit,
  onSubmit,
  setStep,
  setValidityMonthsQuantity,
  setValidityMonth,
}) {
  const [pld, setPld] = useState(false);

  const [step2Selects, setStep2Selects] = useState({
    tipoFonte: [],
    unidades: [],
    tiposPreco: [],
    razaoNegocio: [],
  });

  const loadSelectsStep2 = async () => {
    const { data } = await api.get("/contracts/values");

    setStep2Selects({
      tipoFonte: data["tipo-fonte"],
      unidades: data["unidades"],
      tiposPreco: data["tipos-preco"],
      razaoNegocio: data["razao-negocio"],
    });
  };

  const apiCalcHours = async (dataInicio, dataFim) => {
    if (dataInicio && dataFim) {
      if (
        String(dataInicio.split("-")[0][0]) > 0 &&
        String(dataFim.split("-")[0][0]) > 0
      ) {
        await api
          .post("/math/hours", {
            data_inicio: dataInicio,
            data_fim: dataFim,
          })
          .then((response) => {
            setValue("horas", response.data.horas);
          });
      }
    }
  };

  function fonteSelecionada(value) {
    const fonteSelecionada = step2Selects.tipoFonte.find(
      (fonte) => fonte.value === value
    );

    if (!fonteSelecionada) {
      return setValue("desconto", "Selecione o tipo da fonte");
    }

    setValue("desconto", fonteSelecionada?.desconto + "%");
  }

  function monthDiff(dateFrom, dateTo) {
    return (
      dateTo.getMonth() -
      dateFrom.getMonth() +
      12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
  }

  const onSubmitForm2 = (data) => {
    const months = monthDiff(
      new Date(data.vigenciaInicio),
      new Date(data.vigenciaFim)
    );

    setValidityMonthsQuantity(months);

    if (months <= 1) {
      setValidityMonth({
        month: data.vigenciaInicio.split("-")[1],
        year: data.vigenciaInicio.split("-")[0],
      });
    }

    setStep(3);
  };

  useEffect(() => {
    fonteSelecionada(watch("tipoFonte"));
  }, [watch("tipoFonte")]);

  useEffect(() => {
    loadSelectsStep2();
  }, []);

  useEffect(() => {
    watch("tipoPreco") === 2 ? setPld(true) : setPld(false);
  }, [watch("tipoPreco")]);

  useEffect(() => {
    apiCalcHours(watch("vigenciaInicio"), watch("vigenciaFim"));
  }, [watch("vigenciaInicio"), watch("vigenciaFim")]);

  return (
    <>
      <Step ativo={step > 2 ? true : false}>
        {step > 2 ? <h3>VALORES E VIGÊNCIA</h3> : <h4>VALORES E VIGÊNCIA</h4>}

        {step !== 2 && step !== 5 && (
          <span>
            {step < 2 && "Pendente"}
            {step > 2 && "Completo"}
          </span>
        )}

        {step === 5 && !enableEdit && (
          <button onClick={() => setEnableEdit(2)}>
            <span>Editar seção</span>
            <EditIcon />
          </button>
        )}

        {enableEdit === 2 && (
          <button onClick={handleSubmit(onSubmit)}>
            <span>Salvar seção</span>
            <EditIcon />
          </button>
        )}
      </Step>

      {(step === 2 || step === 5) && (
        <>
          <Grid mt={2} container spacing={2}>
            <Grid item md={2} xs={6}>
              <Label text="Tipo da fonte" />

              <Controller
                name="tipoFonte"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field: { value, onChange, onBlur }, ref }) => (
                  <SelectInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={ref}
                    id="tipoFonte"
                    error={errors.tipoFonte ? true : false}
                    disabled={step === 2 || enableEdit === 2 ? false : true}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>

                    {step2Selects?.tipoFonte?.length > 0 &&
                      step2Selects?.tipoFonte?.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                  </SelectInput>
                )}
              />

              {errors.tipoFonte && (
                <MessageError>Campo necessário</MessageError>
              )}
            </Grid>

            <Grid item md={2} xs={6}>
              <Label text="Desconto" />

              <Controller
                name="desconto"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field: { value, onChange, onBlur }, ref }) => (
                  <Input
                    disabled={true}
                    fullWidth
                    placeholder="Selecione o Tipo da Fonte"
                    ref={ref}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </Grid>

            <Grid item md={2} xs={6}>
              <Label text="Ressarcimento TUSD" />

              <Controller
                name="ressarcimentoTUSD"
                control={control}
                defaultValue=""
                render={({ field: { value, onChange, onBlur }, ref }) => (
                  <NumberFormat
                    thousandSeparator="."
                    decimalScale={2}
                    allowNegative={false}
                    decimalSeparator=","
                    fixedDecimalScale
                    type="text"
                    id="ressarcimentoTUSD"
                    name="ressarcimentoTUSD"
                    getInputRef={ref}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={errors.ressarcimentoTUSD ? true : false}
                    disabled={step === 2 || enableEdit === 2 ? false : true}
                    fullWidth
                    placeholder="00,00"
                    customInput={Input}
                  />
                )}
              />

              {errors.ressarcimentoTUSD && (
                <MessageError>Campo necessário</MessageError>
              )}
            </Grid>
          </Grid>

          <Grid mt={0} container spacing={2}>
            <Grid item md={2} xs={6} sx={{ position: "relative" }}>
              <Label text="Montante" />

              <Controller
                name="montante"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field: { value, onChange, onBlur }, ref }) => (
                  <NumberFormat
                    thousandSeparator="."
                    decimalScale={6}
                    allowNegative={false}
                    decimalSeparator=","
                    fixedDecimalScale
                    type="text"
                    getInputRef={ref}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={errors.montante ? true : false}
                    disabled={step === 2 || enableEdit === 2 ? false : true}
                    fullWidth
                    id="montante"
                    name="montante"
                    placeholder="00,00"
                    customInput={Input}
                  />
                )}
              />
            </Grid>

            <Grid item md={2} xs={6}>
              <Label text="Unidade" />
              <Controller
                name="unidade"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field: { value, onChange, onBlur }, ref }) => (
                  <SelectInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={ref}
                    id="unidade"
                    error={errors.unidade ? true : false}
                    disabled={step === 2 || enableEdit === 2 ? false : true}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {step2Selects?.unidades?.length > 0 &&
                      step2Selects?.unidades?.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                  </SelectInput>
                )}
              />
            </Grid>

            <GridBorder item md={4}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={6}>
                  <Controller
                    name="vigenciaInicio"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field: { value, onChange, onBlur }, ref }) => (
                      <Input
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        ref={ref}
                        error={errors.vigenciaInicio ? true : false}
                        disabled={step === 2 || enableEdit === 2 ? false : true}
                        fullWidth
                        type="month"
                      />
                    )}
                  />
                </Grid>

                <Grid item md={6} xs={6}>
                  <Controller
                    name="vigenciaFim"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field: { value, onChange, onBlur }, ref }) => (
                      <Input
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        ref={ref}
                        error={errors.vigenciaFim ? true : false}
                        disabled={step === 2 || enableEdit === 2 ? false : true}
                        fullWidth
                        type="month"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </GridBorder>
          </Grid>

          <Grid mt={1} container spacing={2}>
            <Grid item md={2} xs={6}>
              <Label text="Tipo Preço" />

              <Controller
                name="tipoPreco"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field: { value, onChange, onBlur }, ref }) => (
                  <SelectInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={ref}
                    id="tipoPreco"
                    error={errors.tipoPreco ? true : false}
                    disabled={step === 2 || enableEdit === 2 ? false : true}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>

                    {step2Selects?.tiposPreco?.length > 0 &&
                      step2Selects?.tiposPreco?.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                  </SelectInput>
                )}
              />

              {errors.tipoPreco && (
                <MessageError>Campo necessário</MessageError>
              )}
            </Grid>

            <Grid item md={2} xs={6}>
              <Label text="Preço da energia" />

              <Controller
                name="precoEnergia"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field: { value, onChange, onBlur }, ref }) => (
                  <NumberFormat
                    thousandSeparator="."
                    decimalScale={2}
                    allowNegative={false}
                    decimalSeparator=","
                    fixedDecimalScale
                    getInputRef={ref}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    id="precoEnergia"
                    name="precoEnergia"
                    error={Boolean(errors.precoEnergia)}
                    disabled={step === 2 || enableEdit === 2 ? false : true}
                    fullWidth
                    placeholder="R$/MWh"
                    customInput={Input}
                  />
                )}
              />

              {errors.precoEnergia && (
                <MessageError>Campo necessário</MessageError>
              )}
            </Grid>

            <Grid item md={2} xs={6}>
              <Label text="Horas" />

              <Controller
                name="horas"
                control={control}
                rules={{ required: "Digite uma data de vigência valida" }}
                defaultValue=""
                render={({ field: { value, onChange, onBlur }, ref }) => (
                  <Input
                    ref={ref}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={errors.horas ? true : false}
                    fullWidth
                    placeholder="Digite a vigência"
                    disabled={true}
                  />
                )}
              />

              {errors.horas && (
                <MessageError>{errors?.message?.horas}</MessageError>
              )}
            </Grid>
            {pld && (
              <Grid item md={2} xs={6}>
                <Label text="Spread" />

                <Controller
                  name="spread"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field: { value, onChange, onBlur }, ref }) => (
                    <NumberFormat
                      thousandSeparator="."
                      decimalScale={2}
                      allowNegative={true}
                      decimalSeparator=","
                      fixedDecimalScale
                      type="text"
                      getInputRef={ref}
                      id="spread"
                      name="spread"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={errors.spread ? true : false}
                      disabled={step === 2 || enableEdit === 2 ? false : true}
                      fullWidth
                      placeholder="R$"
                      customInput={Input}
                    />
                  )}
                />

                {errors.spread && <MessageError>Campo necessário</MessageError>}
              </Grid>
            )}
          </Grid>

          <Grid mt={0} mb={step === 5 ? 4 : 2} container spacing={2}>
            <Grid item md={2} xs={6}>
              <Label text="Razão do negócio" />

              <Controller
                name="razaoNegocio"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field: { value, onChange, onBlur }, ref }) => (
                  <SelectInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={ref}
                    id="razaoNegocio"
                    error={errors.razaoNegocio ? true : false}
                    disabled={step === 2 || enableEdit === 2 ? false : true}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>

                    {step2Selects?.razaoNegocio?.length > 0 &&
                      step2Selects?.razaoNegocio?.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                  </SelectInput>
                )}
              />

              {errors.razaoNegocio && (
                <MessageError>Campo necessário</MessageError>
              )}
            </Grid>
          </Grid>

          {step !== 5 && (
            <ButtonArea>
              <button type="button" onClick={() => setStep(1)}>
                <ArrowBackIcon />

                <span>Voltar</span>
              </button>

              <Button
                type="button"
                onClick={handleSubmit(onSubmitForm2)}
                sx={{ my: 4, paddingVertical: "1em" }}
                text="Prosseguir"
              />
            </ButtonArea>
          )}
        </>
      )}
    </>
  );
}
