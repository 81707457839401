import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import MainModal from "../../../../../components/MainModal";
import CloseIcon from "@mui/icons-material/Close";
import { loadingFullActive } from "../../../../../store/modules/loadingFull/action";
import api from "../../../../../services/api";
import { snackbarActive } from "../../../../../store/modules/snackbar/action";
import { Description, Subtitle, Values } from "./styled";
import Button from "../../../../../components/Button";

export default function ModalCreateAlcada({
  open,
  setOpen,
  contractsIds,
  setSelectedContracts,
}) {
  const { handleSubmit, control, reset } = useForm();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const [infos, setInfos] = useState(null);

  const apiGetContractsInfo = () => {
    setLoading(true);

    const dataContracts = {
      contratos_ids: contractsIds.map((item) => item.id),
    };

    api
      .post("/alcadas/conferir-contratos", dataContracts)
      .then((response) => {
        setInfos(response.data);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const apiCreateAlcada = async (data) => {
    dispatch(loadingFullActive(true));

    const createAlcadaData = {
      observacao: data.description || null,
      contratos_ids: contractsIds.map((item) => item.id),
    };

    await api
      .post(`/alcadas`, createAlcadaData)
      .then((response) => {
        setSelectedContracts([]);

        setOpen(false);

        reset();

        dispatch(
          snackbarActive({
            type: "success",
            message: response?.data?.message || "Alçada criada com sucesso!",
          })
        );
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  useEffect(() => {
    if (open) {
      apiGetContractsInfo();
    }

    return () => {
      setLoading(true);
    };
  }, [open]);

  if (!open) return null;

  return (
    <MainModal open={open} setOpen={setOpen} width="560px">
      <Box
        sx={{
          position: "absolute",
          top: "8px",
          right: "8px",
        }}
      >
        <IconButton title="Fechar" onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Grid mb={0} container>
        <Grid item md={4}>
          {loading ? (
            <Skeleton variant="rounded" height={17} width="70%" />
          ) : (
            <Values>
              <b>Total de contratos:</b> {infos?.total_contratos}
            </Values>
          )}
        </Grid>

        <Grid item md={8}>
          {loading ? (
            <Skeleton variant="rounded" height={17} width="70%" />
          ) : (
            <Values>
              <b>Valor total:</b> {infos?.valor_total}
            </Values>
          )}
        </Grid>
      </Grid>

      <Divider
        sx={{
          my: "16px",
        }}
      />

      {loading ? (
        <Box
          sx={{
            mt: "32px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Skeleton variant="rounded" height={17} width="70%" />
        </Box>
      ) : (
        <>
          {infos?.e_possivel ? (
            <form onSubmit={handleSubmit(apiCreateAlcada)}>
              <Subtitle>Observação</Subtitle>

              <Description>
                Campo não obrigatório para o envio, mas onde é possível enviar
                informações extras
              </Description>

              <Controller
                name="description"
                control={control}
                rules={{ required: false }}
                defaultValue=""
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    fullWidth
                    rows={6}
                    multiline
                    placeholder="Digite alguma informação extra"
                  />
                )}
              />

              <Box
                sx={{
                  mt: "24px",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button text="Confirmar criação" type="submit" />
              </Box>
            </form>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: "16px",
                  textAlign: "center",
                  mt: "32px",
                  color: "var(--text-color)",
                }}
              >
                {infos?.message}
              </Typography>
            </>
          )}
        </>
      )}
    </MainModal>
  );
}
