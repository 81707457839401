import { forwardRef } from "react";
import { Box } from "@mui/material";
import Slide from "@mui/material/Slide";
import MuiAlert from "@mui/material/Alert";

import { SnackbarCustom } from "./styled";

function SlideTransition(props) {
  return <Slide {...props} direction="right" />;
}

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Snackbar({ state, setState, message, type }) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setState(false);
  };

  return (
    <SnackbarCustom
      open={Boolean(state)}
      autoHideDuration={3500}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      key={"slide"}
    >
      <Alert
        icon={
          <Box
            sx={{
              backgroundColor: type === "success" ? "#59db59" : "#ff3f3f",
              width: "14px",
              height: "14px",
              my: "auto",
              borderRadius: "50px",
            }}
          />
        }
        onClose={handleClose}
        severity={type}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </SnackbarCustom>
  );
}
