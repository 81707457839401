import { Select } from "@mui/material";
import styled from "styled-components";

export const SelectI = styled(Select)`
  height: 50px;
  font-size: 15px !important;

  fieldset {
    border-radius: 22px;
  }

  em {
    color: #00000061 !important;
    font-weight: 300 !important;
    font-style: normal !important;
  }
`;
