import styled from "styled-components";

export const BtnAction = styled.span`
  background-color: #da45f8;
  color: white;
  border-radius: 50px;
  padding: 0.5em 1em;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  width: max-content;

  :hover {
    opacity: 0.6;
  }

  :active {
    outline: 1px solid lightgrey;
  }
`;

export const Description = styled.h6`
  font-size: 18px;
  color: var(--text-color);
  margin: 0;
  padding-bottom: 8px;
`;
