import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import Card from "../../components/Card";
import MainTemplate from "../../components/MainTemplate";
import Input from "../../components/Input";
import Button from "../../components/Button";
import {
  Badge,
  MessageError,
  ModalButtonArea,
  TableParams,
  Text,
} from "./styled";
import Label from "../../components/Label";
import api from "../../services/api";
import MainModal from "../../components/MainModal";
import { loadingFullActive } from "../../store/modules/loadingFull/action";
import { snackbarActive } from "../../store/modules/snackbar/action";

export default function ParamsContract() {
  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const [settings, setSettings] = useState([]);

  const [paramsSelected, setParamsSelected] = useState({});
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalActive, setOpenModalActive] = useState(false);

  const handleSelected = (params, table, field) => {
    setParamsSelected({
      table: table,
      field: field,
      id: params.id,
      label: params.label,
      ativo: params.ativo,
    });
    setOpenModalEdit(true);
    setValue("nome", params.label);
  };

  const handleDelete = (table, id) => {
    setParamsSelected({
      table: table,
      id: id,
    });
    setOpenModalDelete(true);
  };

  const handleActive = (table, id) => {
    setParamsSelected({
      table: table,
      id: id,
    });
    setOpenModalActive(true);
  };

  const apiCreate = async (table, field, value) => {
    if (!value) {
      dispatch(
        snackbarActive({
          type: "error",
          message: "Campo necessário",
        })
      );
    } else {
      dispatch(loadingFullActive(true));

      await api
        .post(`/contracts/parameters/${table}/store`, {
          field: field,
          value: value,
        })
        .then((response) => {
          loadSettings();

          dispatch(
            snackbarActive({
              type: "success",
              message:
                response?.data?.message || "Parâmetro adicionado com sucesso!",
            })
          );
        })
        .catch((error) => {
          dispatch(
            snackbarActive({
              type: "error",
              message:
                error?.response?.data?.message || "Tente novamente mais tarde!",
            })
          );
        })
        .finally(() => {
          dispatch(loadingFullActive(false));
        });
    }
  };

  const apiEditParam = async (data) => {
    dispatch(loadingFullActive(true));
    setOpenModalEdit(false);

    const params = {
      id: paramsSelected.id,
      field: paramsSelected.field,
      value: data.nome,
    };

    await api
      .post(`/contracts/parameters/${paramsSelected.table}/update`, params)
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message || "Parâmetro alterado com sucesso!",
          })
        );

        loadSettings();
        reset();
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  const apiDisableParam = async () => {
    dispatch(loadingFullActive(true));
    setOpenModalDelete(false);

    await api
      .post(`/contracts/parameters/${paramsSelected.table}/delete`, {
        id: paramsSelected.id,
      })
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message || "Parâmetro inativado com sucesso!",
          })
        );

        loadSettings();
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  const apiActiveParam = async () => {
    dispatch(loadingFullActive(true));
    setOpenModalActive(false);

    await api
      .post(`/contracts/parameters/${paramsSelected.table}/active`, {
        id: paramsSelected.id,
      })
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message || "Parâmetro ativado com sucesso!",
          })
        );

        loadSettings();
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  const handleCreate = (e, index) => {
    let arraySettings = settings;

    arraySettings[index] = {
      ...arraySettings[index],
      create: e.target.value,
    };

    setSettings([...arraySettings]);
  };

  async function loadSettings() {
    dispatch(loadingFullActive(true));

    let { data } = await api.get("/contracts/parameters");

    for (let i = 0; i < data.length; i++) {
      data[i].create = "";
    }

    setSettings(data);

    dispatch(loadingFullActive(false));
  }

  useEffect(() => {
    loadSettings();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainTemplate title="Parametrização de contratos">
        <Grid container alignItems="stretch" spacing={2}>
          {settings.map((item, index) => (
            <Grid item md={6} sm={6} xs={12} key={index}>
              <Card style={{ height: "100%" }}>
                <Text>{item.name}</Text>

                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Label text={`Nome ${item.name.toLowerCase()}`} />

                    <Input
                      size="small"
                      value={item.create}
                      onChange={(e) => handleCreate(e, index)}
                      placeholder={`Nome da ${item.name.toLowerCase()}`}
                    />
                  </Grid>

                  <Grid item md={6} xs={12} alignSelf="flex-end">
                    <Button
                      sx={{ height: "50px" }}
                      onClick={() =>
                        apiCreate(item.table, item.field, item.create)
                      }
                      text={`Adicionar ${item.name.toLowerCase()}`}
                    />
                  </Grid>
                </Grid>

                <div style={{ overflowX: "auto", width: "100%" }}>
                  <TableParams>
                    <tbody>
                      {item.options.map((option, i) => (
                        <tr key={i}>
                          <td>{option.label}</td>
                          <td>
                            <Badge ativo={option.ativo}>
                              {option.ativo ? "Ativo" : "Inativo"}
                            </Badge>
                          </td>
                          <td>
                            <div>
                              <span
                                style={{ marginRight: 5 }}
                                onClick={() =>
                                  handleSelected(option, item.table, item.field)
                                }
                              >
                                Editar
                              </span>

                              <span
                                style={{ marginRight: 5 }}
                                onClick={() =>
                                  !option.ativo
                                    ? handleActive(item.table, option.id)
                                    : handleDelete(item.table, option.id)
                                }
                              >
                                {!option.ativo ? "Ativar" : "Desativar"}
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </TableParams>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      </MainTemplate>

      <MainModal width={450} open={openModalEdit} setOpen={setOpenModalEdit}>
        <h2 style={{ margin: 0 }}>Editar parâmetro de contrato</h2>

        <Grid mt={1} mb={2} container spacing={2}>
          <Grid item md={12} xs={12}>
            <Label text="Nome" />

            <Controller
              name="nome"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field, ref }) => (
                <Input
                  {...field}
                  ref={ref}
                  id="nome"
                  error={errors.nome ? true : false}
                  fullWidth
                />
              )}
            />

            {errors.nome && <MessageError>Campo necessário</MessageError>}
          </Grid>
        </Grid>

        <ModalButtonArea>
          <button onClick={handleSubmit(apiEditParam)}>Editar</button>
          <button onClick={() => setOpenModalEdit(false)}>Cancelar</button>
        </ModalButtonArea>
      </MainModal>

      <MainModal
        width={450}
        open={openModalDelete}
        setOpen={setOpenModalDelete}
      >
        <h2 style={{ margin: 0 }}>Confirmação</h2>

        <p>Deseja realmente desativar esse parâmetro?</p>

        <ModalButtonArea>
          <button onClick={() => apiDisableParam()}>Desativar</button>
          <button onClick={() => setOpenModalDelete(false)}>Cancelar</button>
        </ModalButtonArea>
      </MainModal>

      <MainModal
        width={450}
        open={openModalActive}
        setOpen={setOpenModalActive}
      >
        <h2 style={{ margin: 0 }}>Confirmação</h2>

        <p>Deseja realmente ativar esse parâmetro?</p>

        <ModalButtonArea>
          <button onClick={() => apiActiveParam()}>Ativar</button>
          <button onClick={() => setOpenModalActive(false)}>Cancelar</button>
        </ModalButtonArea>
      </MainModal>
    </>
  );
}
