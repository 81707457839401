import CloseIcon from "@mui/icons-material/Close";

import { Btn } from "./styled";

export default function ButtonCancel(props) {
  return (
    <Btn {...props}>
      <span>{props.text}</span>
      <CloseIcon />
    </Btn>
  );
}
