import { useDispatch } from "react-redux";

import MainModal from "../../../../components/MainModal";
import { ModalButtonArea } from "./styled";
import { loadingFullActive } from "../../../../store/modules/loadingFull/action";
import api from "../../../../services/api";
import { snackbarActive } from "../../../../store/modules/snackbar/action";

export default function ModalAlcadaProtheus({
  open,
  setOpen,
  alcadaId,
  apiGetAlcadas,
}) {
  const dispatch = useDispatch();

  const apiAlcadaProtheus = async () => {
    dispatch(loadingFullActive(true));

    setOpen(false);

    await api
      .get(`/alcadas/${alcadaId}/protheus`)
      .then((response) => {
        //apiGetAlcadas();

        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message ||
              "Alçada enviada para o Protheus com sucesso!",
          })
        );
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  if (!open) return null;

  return (
    <MainModal width={400} open={open} setOpen={setOpen}>
      <h2 style={{ margin: 0 }}>Confirmação</h2>

      <p>
        Deseja realmente enviar essa alçada{" "}
        <span
          style={{
            color: "var(--main-color)",
          }}
        >
          {alcadaId}
        </span>{" "}
        para o Protheus?
      </p>

      <ModalButtonArea>
        <button onClick={() => apiAlcadaProtheus()}>Enviar</button>

        <button onClick={() => setOpen(false)}>Cancelar</button>
      </ModalButtonArea>
    </MainModal>
  );
}
