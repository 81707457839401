import styled from "styled-components";

export const TableCustom = styled.table`
  width: 100%;
  border-spacing: 0 !important;

  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  thead tr {
    background: #f7f7f7;

    th {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: var(--text-color);
      padding: 1em;
      min-width: 120px;
      z-index: 10;
      position: sticky;
      top: 0;
      background: #f7f7f7;
    }

    :first-child {
      text-align: left;
    }
  }

  tbody {
    tr {
      :hover {
        background: #ededed;
      }

      td {
        padding: 1em;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--text-color);

        border-bottom: 1px solid #dddddd;
      }
    }
  }
`;
