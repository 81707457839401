import styled from "styled-components";

export const Card = styled.div` 
    flex: 1;
    background: ${props => props.activeComplete && !props.active ? "#0E9CFF" : "#CDCDD2"};
    border-radius: 4px;
    cursor: pointer;
    ${props => props.active && "outline: 3px solid #0E9CFF;"}
    position: relative;
    
    h3{
        font-weight: 600;
        font-size: 36px;
        line-height: 54px;
        color: ${props => props.active ? "#0E9CFF" : "#757575"};
        ${props => props.activeComplete && !props.active && "color: white!important"};
        ${props => props.activeComplete && "position: relative; width: 100%; bottom: 15px;"};
        text-align: center;
    }

    p{
        text-align: center;
        margin: 0;
        position: absolute;
        font-size: 14px;
        bottom: 1.3em;
        color: ${props => props.active ? "#0E9CFF" : "white"};
        span{
            font-weight: 700;
        }
    }

    :hover{
        opacity: .6;
    }

    :active{
        outline: 1px solid black;
    }

    @media (max-width: 468px){
        h3{
            padding: 0;
            font-size: 14px;
            line-height: 20px;
        }
    }
`;

export const Empty = styled.div` 
    flex: 1;
`;

export const Calendar = styled.div` 
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    @media (max-width: 468px){
        gap: 5px;
    }
`;

export const Horarios = styled.div` 
    height: 746px;
    overflow: auto;
`;

export const CardHorario = styled.div` 
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-bottom: 15px;

    h3{
        background: ${props => props.active ? "var(--main-color)" : "lightgrey"};
        border-radius: 50px;
        padding: .3em .6em;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: #FFFFFF;
        margin: 0;
        align-self: center;
    }
`;

export const Footer = styled.footer` 
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 1em;
    z-index: 2;
`;