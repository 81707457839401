import styled from "styled-components";

export const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 1em;
  z-index: 2;
  background: #ffffff85;
  width: calc(100% - var(--sidemenu-width));
  backdrop-filter: blur(2px);

  display: flex;
  align-items: center;
  justify-content: end;
  gap: 24px;
`;
