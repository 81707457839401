import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Grid, MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import api from "../../../../services/api";
import { ButtonArea, Step } from "./styled";
import MessageError from "../../../../components/MessageError";
import Label from "../../../../components/Label";
import SelectInput from "../../../../components/SelectInput";
import Button from "../../../../components/Button";

export default function StepDadosProtheus({
  step,
  control,
  errors,
  handleSubmit,
  enableEdit,
  setEnableEdit,
  onSubmit,
  setStep,
  typeProduct,
}) {
  const onSubmitForm4 = (data) => setStep(5);

  const [step4Selects, setStep4Selects] = useState({
    centroCusto: [],
    classesValor: [],
    produtos: [],
    tes: [],
    mensagemNota: [],
  });

  async function loadSelectsStep4() {
    const { data } = await api.get("/contracts/protheus");

    setStep4Selects({
      centroCusto: data["centro-custo"],
      classesValor: data["classes_valor"],
      produtos: data["produtos"],
      tes: data["tes"],
      mensagemNota: data["mensagem-nota"],
    });
  }

  useEffect(() => {
    loadSelectsStep4();
  }, []);

  return (
    <>
      <Step ativo={step > 4 ? true : false}>
        {step > 4 ? <h3>DADOS PROTHEUS</h3> : <h4>DADOS PROTHEUS</h4>}

        {step !== 4 && step !== 5 && (
          <span>
            {step < 4 && "Pendente"}
            {step > 4 && "Completo"}
          </span>
        )}

        {step === 5 && !enableEdit && (
          <button onClick={() => setEnableEdit(4)}>
            <span>Editar seção</span>
            <EditIcon />
          </button>
        )}

        {enableEdit === 4 && (
          <button onClick={handleSubmit(onSubmit)}>
            <span>Salvar seção</span>
            <EditIcon />
          </button>
        )}
      </Step>

      {(step === 4 || step === 5) && (
        <>
          <Grid mt={2} container spacing={2}>
            <Grid item md={2} xs={6}>
              <Label text="Classe de valor" />

              <Controller
                name="classeValor"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <SelectInput
                    {...field}
                    ref={ref}
                    id="classeValor"
                    error={errors.classeValor ? true : false}
                    disabled={step === 4 || enableEdit === 4 ? false : true}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>

                    {step4Selects?.classesValor?.length > 0 &&
                      step4Selects?.classesValor?.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                  </SelectInput>
                )}
              />

              {errors.classeValor && (
                <MessageError>Campo necessário</MessageError>
              )}
            </Grid>
            <Grid item md={2} xs={6}>
              <Label text="Centro de custo" />

              <Controller
                name="centroCusto"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <SelectInput
                    {...field}
                    ref={ref}
                    id="centroCusto"
                    error={errors.centroCusto ? true : false}
                    disabled={step === 4 || enableEdit === 4 ? false : true}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {step4Selects?.centroCusto?.length > 0 &&
                      step4Selects?.centroCusto?.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                  </SelectInput>
                )}
              />

              {errors.centroCusto && (
                <MessageError>Campo necessário</MessageError>
              )}
            </Grid>
          </Grid>

          <Grid mt={0} container spacing={2}>
            {(String(typeProduct) === "Buy" ||
              String(typeProduct) === "Both") && (
              <Grid item md={2} xs={6}>
                <Label text="Produto de compra" />

                <Controller
                  name="produtoCompra"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field, ref }) => (
                    <SelectInput
                      {...field}
                      ref={ref}
                      id="produtoCompra"
                      error={errors.produtoCompra ? true : false}
                      disabled={
                        step === 4 || enableEdit === 4 || enableEdit === 1
                          ? false
                          : true
                      }
                      fullWidth
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Selecione</em>
                      </MenuItem>
                      {step4Selects?.produtos?.length > 0 &&
                        step4Selects?.produtos?.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                    </SelectInput>
                  )}
                />

                {errors.produtoCompra && (
                  <MessageError>Campo necessário</MessageError>
                )}
              </Grid>
            )}

            {(String(typeProduct) === "Sell" ||
              String(typeProduct) === "Both") && (
              <Grid item md={2} xs={6}>
                <Label text="Produto de venda" />

                <Controller
                  name="produtoVenda"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field, ref }) => (
                    <SelectInput
                      {...field}
                      ref={ref}
                      id="produtoVenda"
                      error={errors.produtoVenda ? true : false}
                      disabled={
                        step === 4 || enableEdit === 4 || enableEdit === 1
                          ? false
                          : true
                      }
                      fullWidth
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Selecione</em>
                      </MenuItem>
                      {step4Selects?.produtos?.length > 0 &&
                        step4Selects?.produtos?.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                    </SelectInput>
                  )}
                />

                {errors.produtoVenda && (
                  <MessageError>Campo necessário</MessageError>
                )}
              </Grid>
            )}

            {(String(typeProduct) === "Sell" ||
              String(typeProduct) === "Both") && (
              <Grid item md={2} xs={6}>
                <Label text="TES" />

                <Controller
                  name="tes"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field, ref }) => (
                    <SelectInput
                      {...field}
                      ref={ref}
                      id="tes"
                      error={errors.tes ? true : false}
                      disabled={
                        step === 4 || enableEdit === 4 || enableEdit === 1
                          ? false
                          : true
                      }
                      fullWidth
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Selecione</em>
                      </MenuItem>
                      {step4Selects?.tes?.length > 0 &&
                        step4Selects?.tes?.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                    </SelectInput>
                  )}
                />

                {errors.tes && <MessageError>Campo necessário</MessageError>}
              </Grid>
            )}
          </Grid>

          {(String(typeProduct) === "Sell" ||
            String(typeProduct) === "Both") && (
            <Grid mt={0} mb={step === 5 ? 4 : 2} container spacing={2}>
              <Grid item md={2} xs={6}>
                <Label text="Mensagem nota" />

                <Controller
                  name="mensagemNota"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field, ref }) => (
                    <SelectInput
                      {...field}
                      ref={ref}
                      id="mensagemNota"
                      error={errors.mensagemNota ? true : false}
                      disabled={step === 4 || enableEdit === 4 ? false : true}
                      fullWidth
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Selecione</em>
                      </MenuItem>

                      {step4Selects?.mensagemNota?.length > 0 &&
                        step4Selects?.mensagemNota?.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                    </SelectInput>
                  )}
                />

                {errors.mensagemNota && (
                  <MessageError>Campo necessário</MessageError>
                )}
              </Grid>
            </Grid>
          )}

          {step !== 5 && (
            <ButtonArea>
              <button onClick={() => setStep(3)}>
                <ArrowBackIcon />
                <span>Voltar</span>
              </button>

              <Button
                onClick={handleSubmit(onSubmitForm4)}
                sx={{ my: 4, paddingVertical: "1em" }}
                text="Revisar e concluir"
              />
            </ButtonArea>
          )}
        </>
      )}
    </>
  );
}
