import { useEffect, useState } from "react";
import {
  Grid,
  MenuItem,
  InputAdornment,
  TextField,
  Autocomplete,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import Pagination from "@mui/material/Pagination";
import { useDispatch } from "react-redux";

import Card from "../../components/Card";
import MainTemplate from "../../components/MainTemplate";
import Table from "../../components/Table";
import {
  Input,
  SelectInput,
  Btn,
  Text,
  Bold,
  AreaFilters,
  BtnAction,
} from "./styled";
import Label from "../../components/Label";
import api from "../../services/api";
import ModalEditarPrecoEnergia from "./components/ModalEditarPrecoEnergia";
import { snackbarActive } from "../../store/modules/snackbar/action";

export default function AtualizacaoPeriodo() {
  const dispatch = useDispatch();

  const [contentSelectPontaA, setContentSelectPontaA] = useState([]);
  const [contentSelectPontaB, setContentSelectPontaB] = useState([]);
  const [contentSelectFatoContrato, setContentSelectFatoContrato] = useState(
    []
  );
  const [contentSelectFonteEnergia, setContentSelectFonteEnergia] = useState(
    []
  );
  const [contentSelectTranche, setContentSelectTranche] = useState([]);

  const [contentSelectInternoExterno, setContentSelectInternoExterno] =
    useState([]);
  const [contentSelectTipoOperacao, setContentSelectTipoOperacao] = useState(
    []
  );
  const [contentSelectRazaoNegocio, setContentSelectRazaoNegocio] = useState(
    []
  );
  const [contentSelectSubmercado, setContentSelectSubmercado] = useState([]);
  const [contentSelectStatus, setContentSelectStatus] = useState([]);

  const [page, setPage] = useState(1);
  const [contentTable, setContentTable] = useState([]);
  const [dataContractPagination, setDataContractPagination] = useState({});

  const [loadingTable, setLoadingTable] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [inputValuePontaA, setInputValuePontaA] = useState("");

  const [selectedContract, setselectedContract] = useState({});

  const [filter, setFilter] = useState({
    startData: "",
    finalData: "",
    pontaA: null,
    pontaB: null,
    contract: "",
    energy: "",
    tranche: "",
    code: "",
    internoExterno: "",
    tipoOperacao: "",
    tipoFonte: "",
    razaoNegocio: "",
    submercado: "",
    status: "",
  });

  const [isModalEditarPrecoEnergiaAberto, setIsModalEditarPrecoEnergiaAberto] =
    useState(false);

  const headerTable = [
    "Código do contrato",
    "Interno/Externo",
    "Ínicio de vigência - Final de vigência",
    "Ponta A",
    "Fato do contrato",
    "Ponta B",
    "Status",
    "Preço energia inicial",
    "Preço energia atual",
    "Faturamento",
    "Volume MW/h",
    "Volume MW/m",
    "Spread",
    "Ações",
  ];

  const getDataApiContracts = async (page = 1) => {
    setLoadingTable(true);

    await api
      .get(`/contracts/period`, {
        params: {
          page: page,
          "inicio-vigencia": filter.startData || null,
          "ponta-a": filter.pontaA?.value || null,
          "fato-contrato": filter.contract || null,
          "fonte-energia": filter.energy || null,
          tranche: filter.tranche || null,
          "final-vigencia": filter.finalData || null,
          "ponta-b": filter.pontaB?.value || null,
          "codigo-contrato": filter.code || null,
          "interno-externo": filter.internoExterno || null,
          "tipo-operacao": filter.tipoOperacao || null,
          "razao-negocio": filter.razaoNegocio || null,
          submercado: filter.submercado || null,
          status: filter.status || null,
        },
      })
      .then((response) => {
        setDataContractPagination(response.data);

        setContentTable(response.data.items.data);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message: error?.response?.data?.message || "Erro pegar contratos!",
          })
        );
      })
      .finally(() => {
        setLoadingTable(false);
      });
  };

  const getDataApiFilters = async () => {
    await api.get(`/filters/ponta-a`).then((response) => {
      setContentSelectPontaA(response.data);
    });

    await api.get(`/filters/ponta-b`).then((response) => {
      setContentSelectPontaB(response.data);
    });

    await api.get(`/filters/fato-contrato`).then((response) => {
      setContentSelectFatoContrato(response.data);
    });

    await api.get(`/filters/fonte-energia`).then((response) => {
      setContentSelectFonteEnergia(response.data);
    });

    await api.get(`/filters/tranche`).then((response) => {
      setContentSelectTranche(response.data);
    });

    await api.get(`/filters/interno-externo`).then((response) => {
      setContentSelectInternoExterno(response.data);
    });

    await api.get(`/filters/tipo-operacao`).then((response) => {
      setContentSelectTipoOperacao(response.data);
    });

    await api.get(`/filters/razao-negocio`).then((response) => {
      setContentSelectRazaoNegocio(response.data);
    });

    await api.get(`/filters/status`).then((response) => {
      setContentSelectStatus(response.data);
    });

    await api.get(`/filters/submercado`).then((response) => {
      setContentSelectSubmercado(response.data);
    });
  };

  const cleanFilters = () => {
    setFilter({
      startData: "",
      finalData: "",
      pontaA: null,
      pontaB: null,
      contract: "",
      energy: "",
      tranche: "",
      code: "",
      internoExterno: "",
      tipoOperacao: "",
      tipoFonte: "",
      razaoNegocio: "",
      submercado: "",
      status: "",
    });
  };

  const handlePagination = (page) => {
    setPage(page);
    getDataApiContracts(page);
  };

  useEffect(() => {
    getDataApiContracts();
    getDataApiFilters();
  }, []);

  useEffect(() => {
    handlePagination(1);
  }, [filter]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainTemplate title="Atualização de valores por periodo">
        <Grid pt={1} container alignItems="stretch" spacing={1}>
          <Grid item md={10} sm={9} xs={12}>
            <Card style={{ height: "100%" }}>
              <Text>Filtros</Text>

              <AreaFilters>
                <div className="inputDiv">
                  <Label text="Vigência" />
                  <Input
                    value={filter.startData}
                    onInput={(e) =>
                      setFilter({ ...filter, startData: e.target.value })
                    }
                    size="small"
                    fullWidth
                    type="month"
                  />
                </div>

                <div className="inputDiv">
                  <Label text="Ponta A" />

                  <Autocomplete
                    value={filter.pontaA}
                    onChange={(event, newValue) => {
                      setFilter({ ...filter, pontaA: newValue });
                    }}
                    inputValue={inputValuePontaA}
                    onInputChange={(event, newInputValue) => {
                      setInputValuePontaA(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={contentSelectPontaA}
                    noOptionsText="Não foi encontrado"
                    sx={{
                      width: "100%",
                      ".MuiInputBase-root": {
                        height: "38px!important",
                      },
                      input: {
                        fontSize: "14px!important",
                        height: "8px",
                      },
                      fieldset: { borderRadius: "100px" },
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Todos" />
                    )}
                    getOptionLabel={(option) => option.label}
                  />
                </div>

                <div className="inputDiv">
                  <Label text="Fato do contrato" />
                  <SelectInput
                    size="small"
                    value={filter.contract}
                    onChange={(e) =>
                      setFilter({ ...filter, contract: e.target.value })
                    }
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Todos</em>
                    </MenuItem>

                    {contentSelectFatoContrato?.map((value) => (
                      <MenuItem key={value.value} value={value.value}>
                        {value.label}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </div>

                <div className="inputDiv">
                  <Label text="Fonte de energia" />
                  <SelectInput
                    size="small"
                    displayEmpty
                    value={filter.energy}
                    onChange={(e) =>
                      setFilter({ ...filter, energy: e.target.value })
                    }
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Todos</em>
                    </MenuItem>

                    {contentSelectFonteEnergia?.map((value) => (
                      <MenuItem key={value.value} value={value.value}>
                        {value.label}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </div>

                <div className="inputDiv">
                  <Label text="Tranche" />
                  <SelectInput
                    size="small"
                    displayEmpty
                    value={filter.tranche}
                    onChange={(e) =>
                      setFilter({ ...filter, tranche: e.target.value })
                    }
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Todos</em>
                    </MenuItem>

                    {contentSelectTranche.length > 0 &&
                      contentSelectTranche?.map((value) => (
                        <MenuItem key={value.value} value={value.value}>
                          {value.label}
                        </MenuItem>
                      ))}
                  </SelectInput>
                </div>

                <div className="inputDiv">
                  <Label text="Interno/Externo" />
                  <SelectInput
                    size="small"
                    value={filter.internoExterno}
                    onChange={(e) =>
                      setFilter({ ...filter, internoExterno: e.target.value })
                    }
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Todos</em>
                    </MenuItem>

                    {contentSelectInternoExterno?.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </div>

                <div className="inputDiv">
                  <Label text="Tipo de operação" />
                  <SelectInput
                    size="small"
                    value={filter.tipoOperacao}
                    onChange={(e) =>
                      setFilter({ ...filter, tipoOperacao: e.target.value })
                    }
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Todos</em>
                    </MenuItem>

                    {contentSelectTipoOperacao?.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </div>

                <div className="inputDiv">
                  <Label text="Razão Negócio" />
                  <SelectInput
                    size="small"
                    value={filter.razaoNegocio}
                    onChange={(e) =>
                      setFilter({ ...filter, razaoNegocio: e.target.value })
                    }
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Todos</em>
                    </MenuItem>

                    {contentSelectRazaoNegocio?.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </div>

                <div className="inputDiv">
                  <Label text="Submercado" />
                  <SelectInput
                    size="small"
                    displayEmpty
                    value={filter.submercado}
                    onChange={(e) =>
                      setFilter({ ...filter, submercado: e.target.value })
                    }
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Todos</em>
                    </MenuItem>

                    {contentSelectSubmercado?.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </div>

                <div className="inputDiv">
                  <Label text="Status" />
                  <SelectInput
                    size="small"
                    displayEmpty
                    value={filter.status}
                    onChange={(e) =>
                      setFilter({ ...filter, status: e.target.value })
                    }
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Todos</em>
                    </MenuItem>

                    {contentSelectStatus?.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </div>

                <div className="inputDiv" style={{ alignSelf: "flex-end" }}>
                  <Label text="Ponta B" />

                  <Autocomplete
                    value={filter.pontaB}
                    onChange={(event, newValue) => {
                      setFilter({ ...filter, pontaB: newValue });
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={contentSelectPontaB}
                    noOptionsText="Não foi encontrado"
                    sx={{
                      width: "100%",
                      ".MuiInputBase-root": {
                        height: "38px!important",
                      },
                      input: {
                        fontSize: "14px!important",
                        height: "8px",
                      },
                      fieldset: { borderRadius: "100px" },
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Todos" />
                    )}
                    getOptionLabel={(option) => option.label}
                  />
                </div>

                <div className="inputDiv" style={{ alignSelf: "flex-end" }}>
                  <Label text="Código do contrato" />
                  <Input
                    size="small"
                    value={filter.code}
                    onChange={(e) =>
                      setFilter({ ...filter, code: e.target.value })
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    placeholder="Código do contrato"
                  />
                </div>

                <div className="inputDiv" style={{ alignSelf: "flex-end" }}>
                  <Btn
                    onClick={() => {
                      setPage(1);
                      getDataApiContracts(1);
                    }}
                    fullWidth
                    style={{
                      color: "white",
                      backgroundColor: "var(--main-color)",
                    }}
                  >
                    <span>Filtrar contratos</span>
                    <ArrowForwardIcon />
                  </Btn>
                </div>

                <div
                  className="lastDivFilter inputDiv"
                  style={{ alignSelf: "flex-end" }}
                >
                  <Btn
                    onClick={() => cleanFilters()}
                    fullWidth
                    style={{
                      color: "black",
                      backgroundColor: "white",
                      border: "1px solid #606060",
                    }}
                  >
                    <span>Limpar filtros</span>
                    <RestartAltIcon />
                  </Btn>
                </div>

                <div style={{ alignSelf: "flex-end" }} />
              </AreaFilters>
            </Card>
          </Grid>

          <Grid item md={2} sm={3} xs={12}>
            <Card>
              <Text>Total de registros</Text>

              <Bold>
                {dataContractPagination?.total || 0}
                <span style={{ fontSize: "13px" }}>contratos</span>
              </Bold>

              <Text style={{ paddingTop: "36px" }}>Encontrados</Text>
              <Bold>
                {dataContractPagination?.on_search || 0}
                <span style={{ fontSize: "13px" }}>contratos</span>
              </Bold>
            </Card>
          </Grid>
        </Grid>

        <Grid pt={1} container spacing={1}>
          <Grid item md={12} xs={12}>
            <Card style={{ padding: 0 }}>
              <Table
                header={headerTable}
                loading={loadingTable}
                scrollTopActive={true}
              >
                {contentTable?.length > 0 &&
                  contentTable?.map((item) => (
                    <tr key={item.id}>
                      <td>{item["Código interno"]}</td>
                      <td>{item["Interno/Externo"]}</td>
                      <td>
                        {item["Inicio suprimento"]} - {item["Fim suprimento"]}
                      </td>
                      <td>{item["Ponta A"]}</td>
                      <td>{item["Fato do Contrato"]}</td>
                      <td>{item["Ponta B"]}</td>
                      <td>{item["Status"]}</td>
                      <td>
                        {Number(
                          item["Preço da energia inicial"]
                        ).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </td>
                      <td>
                        {Number(item["Preço da energia"]).toLocaleString(
                          "pt-br",
                          {
                            style: "currency",
                            currency: "BRL",
                          }
                        )}
                      </td>
                      <td>
                        {item["Faturamento"].toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </td>
                      <td>{item["Volume MWh"]}</td>
                      <td>{item["Volume MWm"]}</td>
                      <td>
                        {Number(item["Spread"]).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </td>
                      <td>
                        <BtnAction
                          style={{ marginLeft: 10, background: "#B0A5F3" }}
                          onClick={() => {
                            setselectedContract(item);
                            setIsModalEditarPrecoEnergiaAberto(true);
                          }}
                        >
                          Atualizar preço de energia
                        </BtnAction>
                      </td>
                    </tr>
                  ))}
              </Table>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                  paddingBottom: 20,
                }}
              >
                <Pagination
                  count={dataContractPagination.items?.last_page}
                  page={page}
                  onChange={(event, value) => handlePagination(value)}
                />
              </div>
            </Card>
          </Grid>
        </Grid>
      </MainTemplate>

      <ModalEditarPrecoEnergia
        open={isModalEditarPrecoEnergiaAberto}
        setOpen={setIsModalEditarPrecoEnergiaAberto}
        contract={selectedContract}
        getDataApiContracts={getDataApiContracts}
      />
    </>
  );
}
