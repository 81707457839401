import styled from "styled-components";

export const Title = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0;

  padding-top: 8px;

  text-align: center;

  color: var(--text-color);
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;

  color: var(--text-color);

  text-align: center;

  padding-top: 8px;

  a {
    color: var(--main-color);
  }
`;

export const ButtonUpload = styled.button`
  background: #da45f8;
  border-radius: 34px;
  height: 48px;

  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
  padding: 0 2em;
  border: none;

  font-weight: 400;
  font-size: 14px;
  line-height: 25px;

  :hover {
    opacity: 0.8;
  }
`;
