import { Select, TextField, Button } from "@mui/material";
import styled from "styled-components";

export const Text = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: var(--text-color);
  margin: 0;
  padding-bottom: 0.7em;
`;

export const SelectInput = styled(Select)`
  font-size: 14px;

  fieldset {
    border-radius: 55px;
  }

  em {
    color: #00000061;
    font-weight: 300 !important;
  }
`;

export const Btn = styled(Button)`
  border-radius: 22px !important;
  display: flex;
  width: max-content !important;
  min-width: 100% !important;
  text-transform: none !important;

  span {
    padding-right: 0.5em;
    text-transform: none;
  }

  :hover {
    opacity: 0.6;
  }
`;

export const Input = styled(TextField)`
  fieldset {
    border-radius: 55px !important;
  }

  input {
    font-size: 14px;
  }
`;

export const AreaFiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
  width: 100%;

  .inputDiv {
    min-width: 100px;
    max-width: 100%;
  }

  @media (max-width: 1000px) and (min-width: 468px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;

    .lastDivFilter {
      margin-top: 0.5em;
    }
  }

  @media (max-width: 468px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;

    .lastDivFilter {
      margin-top: 1em;
    }
  }
`;
