import styled from "styled-components";

export const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 1em;
  z-index: 2;
`;

export const SuccessArea = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 2em;

  div {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    svg {
      width: 100%;
      font-size: 150px;
      color: grey;
    }

    button {
      background-color: var(--main-color) !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 25px !important;
      color: #ffffff !important;
      border-radius: 34px !important;
      border: none;
      padding: 0.7em 1.5em !important;
      text-transform: none !important;
      min-width: 200px;
    }

    button:hover {
      opacity: 0.6;
    }
  }
`;

export const ModulacaoUploadArea = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;

  div {
    flex-direction: column;
    display: flex;
    text-align: center;
  }

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    margin: 0;
    color: var(--text-color);
    padding-top: 1em;
    padding-bottom: 0.4em;
  }

  p {
    font-style: normal;
    font-weight: 400;
    padding-bottom: 1em;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    display: block;
    align-items: flex-end;
    color: var(--text-color);
  }

  a {
    color: var(--main-color);
  }

  button {
    display: flex;
    border-radius: 22px;
    background: var(--main-color);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #ffffff;
    border: 0;
    padding: 0.5em 1.5em;
  }
`;

export const BackgroundIcon = styled.div`
  background: var(--main-color);
  display: flex;
  align-self: center;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 1em;
  border-radius: 100px;

  svg {
    font-size: 40px;
    color: white;
  }
`;
