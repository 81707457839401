import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CheckIcon from "@mui/icons-material/Check";

import api from "../../../../services/api";
import { Footer, Title } from "./styled";
import { snackbarActive } from "../../../../store/modules/snackbar/action";
import { Box, Checkbox, Fab } from "@mui/material";
import Table from "../../../../components/Table";
import ModalProtheus from "./ModalProtheus";

export default function TableContracts({
  alcadaId,
  selectedsIdsProtheus,
  setSelectedsIdsProtheus,
}) {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const [contracts, setContracts] = useState([]);

  const [isModalProtheusOpen, setIsModalProtheusOpen] = useState(false);

  const apiGetContractsByAlcadaId = () => {
    setLoading(true);

    api
      .get(`/alcadas/${alcadaId}/contratos`)
      .then((response) => {
        setContracts(response.data);
      })
      .catch((error) => {
        dispatch(
          snackbarActive(
            "error",
            error.response.data.message ||
              `Erro ao buscar contratos da alçada ${alcadaId}`
          )
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAddOrRemoveIdProtheus = (objectContract, action) => {
    if (action) {
      setSelectedsIdsProtheus((prev) => [...prev, objectContract]);
      return;
    }

    const newList = selectedsIdsProtheus.filter((item) => {
      return item.id !== objectContract.id;
    });

    setSelectedsIdsProtheus([...newList]);
  };

  useEffect(() => {
    apiGetContractsByAlcadaId();
  }, []);

  return (
    <>
      <Box
        sx={{
          padding: "0 16px 16px 16px",
        }}
      >
        <Title>Contratos da alçada #{alcadaId}</Title>

        <Table
          header={[
            "Envia Protheus",
            "Interno/Externo",
            "Código interno",
            "Título",
            "Razão do negócio",
            "Tipo de operação",
            "Ponta A",
            "Fato do contrato",
            "Ponta B",
            "Tipo da fonte",
            "Volume MWh",
            "Volume MWm",
            "Submercado",
            "Faturamento",
            "Preço energia inicial",
            "Preço energia atual",
            "Spread",
            "Inicio suprimento",
            "Fim suprimento",
            "Status",
          ]}
          loading={loading}
          scrollTopActive={false}
        >
          {contracts?.length > 0 &&
            contracts?.map((item) => (
              <tr key={item.id}>
                <td style={{ textAlign: "left" }}>
                  {item.envia_protheus === 1 && (
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: "var(--main-color)",
                        },
                      }}
                      checked={
                        !!selectedsIdsProtheus.some((e) => e.id === item.id)
                      }
                      onChange={(e) =>
                        handleAddOrRemoveIdProtheus(
                          {
                            id: item.id,
                            label: `${alcadaId} | ${item["Código interno"]} - ${item["Inicio suprimento"]} | ${item["Inicio suprimento"]}`,
                          },
                          e.target.checked
                        )
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  )}
                </td>
                <td>{item["Interno/Externo"]}</td>
                <td>{item["Código interno"]}</td>
                <td>{item["Título"]}</td>
                <td>{item["Razão do negócio"]}</td>
                <td>{item["Tipo de operação"]}</td>
                <td>{item["Ponta A"]}</td>
                <td>{item["Fato do Contrato"]}</td>
                <td>{item["Ponta B"]}</td>
                <td>{item["Tipo de fonte"]}</td>
                <td>{item["Volume MWh"]}</td>
                <td>{item["Volume MWm"]}</td>
                <td>{item["Submercado"]}</td>
                <td>{item["Faturamento"]}</td>
                <td>{item["Preço da energia inicial"]}</td>
                <td>{item["Preço da energia"]}</td>
                <td>{item["Spread"]}</td>
                <td>{item["Inicio suprimento"]}</td>
                <td>{item["Fim suprimento"]}</td>
                <td>{item["Status"]}</td>
              </tr>
            ))}
        </Table>
      </Box>

      <ModalProtheus
        open={isModalProtheusOpen}
        setOpen={setIsModalProtheusOpen}
        handleGetContracts={apiGetContractsByAlcadaId}
        selectedsIdsProtheus={selectedsIdsProtheus}
        setSelectedsIdsProtheus={setSelectedsIdsProtheus}
      />

      {selectedsIdsProtheus?.length > 0 && (
        <Footer>
          <Fab
            onClick={() => setIsModalProtheusOpen(true)}
            sx={{
              textTransform: "none",
              backgroundColor: "#DA45F8",
              color: "#FFF",
            }}
            variant="extended"
            color="primary"
            aria-label="add"
          >
            Enviar contrato(s) Protheus
            <CheckIcon sx={{ paddingLeft: 1 }} />
          </Fab>
        </Footer>
      )}
    </>
  );
}
