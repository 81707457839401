import styled from "styled-components";
import { Button } from "@mui/material";

export const Text = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: var(--text-color);
  margin: 0;
  padding-bottom: 0.7em;
`;

export const Bold = styled.h3`
  font-weight: 700;
  font-size: 32px;
  color: var(--text-color);
  margin: 0;

  span {
    padding-left: 0.3em;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: var(--text-color);
  }
`;

export const Badge = styled.span`
  background-color: #cce6ff;
  display: block;
  color: #0080ff;
  width: max-content;
  padding: 0.4em 0.8em;
  border-radius: 22px;
`;

export const ButtonCustom = styled(Button)`
  height: 40px !important;
  padding: 0 20px !important;
  border-radius: 1000px !important;
  background-color: #0080ff !important;
  color: #fff !important;
  text-transform: none !important;
  flex-shrink: 0 !important;

  &:hover {
    background-color: #005cb2 !important;
  }
`;

export const ButtonCustomSecondary = styled(Button)`
  height: 40px !important;
  padding: 0 20px !important;
  border-radius: 1000px !important;
  background-color: #c954f1 !important;
  color: #fff !important;
  text-transform: none !important;
  flex-shrink: 0 !important;

  &:hover {
    background-color: #a63fc4 !important;
  }
`;
