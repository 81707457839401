import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, Grid } from "@mui/material";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import BallotIcon from "@mui/icons-material/Ballot";

import Card from "../../components/Card";
import MainTemplate from "../../components/MainTemplate";
import api from "../../services/api";
import { ButtonUpload, Description, Title } from "./styled";
import ExemploSazo from "../../assets/xlsx/exemploSazo.xlsx";
import ExemploModulo from "../../assets/xlsx/exemploModulacao.xlsx";
import { loadingFullActive } from "../../store/modules/loadingFull/action";
import { snackbarActive } from "../../store/modules/snackbar/action";

export default function SazonalidadeModulacao() {
  const dispatch = useDispatch();

  const apiEnviarArquivoSazonalidade = async () => {
    dispatch(loadingFullActive(true));

    const file = document.getElementById("file_sazonalidade");

    const newData = new FormData();
    newData.append("file", file.files[0]);

    await api
      .post("/sazonalidade", newData)
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message || "Sazonalidade importada com sucesso!",
          })
        );
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  const apiEnviarArquivoModulacao = async () => {
    dispatch(loadingFullActive(true));

    const file = document.getElementById("file_modulacao");

    const newData = new FormData();
    newData.append("file", file.files[0]);

    await api
      .post("/modulacao ", newData)
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message || "Modulação importada com sucesso!",
          })
        );
      })
      .catch((error) => {
        if (error.response.status === 422) {
          dispatch(
            snackbarActive({
              type: "error",
              message:
                error?.response?.data?.errors.join(" - ") ||
                "Tente novamente mais tarde!",
            })
          );

          return;
        }

        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message ||
              "Arquivo não está igual ao modelo",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainTemplate title="Sazonalidade/Modulação">
        <Grid container spacing={2}>
          <Grid item lg={4} md={6} sm={6}>
            <Card>
              <Box sx={{ mt: 1, display: "flex", justifyContent: "center" }}>
                <Box
                  sx={{
                    width: "75px",
                    height: "75px",
                    borderRadius: "100px",
                    backgroundColor: "var(--main-color)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <BallotIcon sx={{ fontSize: 42, color: "#FFFFFF" }} />
                </Box>
              </Box>

              <Title>Você precisa importar uma planilha com a modulação</Title>

              <Description>
                Para contratos modulares com mais de um mês de vigência, uma
                planilha com a modulação deve ser importada de acordo com{" "}
                <a href={ExemploModulo} download>
                  o modelo
                </a>
                .
              </Description>

              <input
                style={{ display: "none" }}
                onChange={() => apiEnviarArquivoModulacao()}
                type="file"
                id="file_modulacao"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />

              <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
                <ButtonUpload
                  onClick={() =>
                    document.getElementById("file_modulacao").click()
                  }
                >
                  Importar XLSX
                  <BackupTableIcon />
                </ButtonUpload>
              </Box>
            </Card>
          </Grid>

          <Grid item lg={4} md={6} sm={6}>
            <Card>
              <Box sx={{ mt: 1, display: "flex", justifyContent: "center" }}>
                <Box
                  sx={{
                    width: "75px",
                    height: "75px",
                    borderRadius: "100px",
                    backgroundColor: "var(--main-color)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <BallotIcon sx={{ fontSize: 42, color: "#FFFFFF" }} />
                </Box>
              </Box>
              <Title>
                Você precisa importar uma planilha com a sazonalidade
              </Title>
              <Description>
                Para contratos com sazonalidade você pode usar este{" "}
                <a href={ExemploSazo} download>
                  modelo
                </a>
                .
              </Description>

              <input
                style={{ display: "none" }}
                type="file"
                onChange={() => apiEnviarArquivoSazonalidade()}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                id="file_sazonalidade"
              />

              <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
                <ButtonUpload
                  onClick={() =>
                    document.getElementById("file_sazonalidade").click()
                  }
                >
                  Importar XLSX
                  <BallotIcon />
                </ButtonUpload>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </MainTemplate>
    </>
  );
}
