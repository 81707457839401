import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import Card from "../../../../components/Card";
import { Input, SelectInput, AreaFiltersWrapper, Btn, Text } from "./styled";
import api from "../../../../services/api";
import Label from "../../../../components/Label";

export default function AreaFilters({
  filter,
  setFilter,
  setContentSelectTipoOperacao,
  setContentSelectRazaoNegocio,
  contentSelectTipoOperacao,
  contentSelectRazaoNegocio,
  setPage,
  getDataApiContracts,
}) {
  const [loadingFilters, setLoadingFilters] = useState({
    pontaA: true,
    fatoContrato: true,
    fonteEnergia: true,
    tranche: true,
    internoExterno: true,
    tipoOperacao: true,
    razaoNegocio: true,
    submercado: true,
    status: true,
    pontaB: true,
  });

  const [contentSelectSubmercado, setContentSelectSubmercado] = useState([]);
  const [contentSelectStatus, setContentSelectStatus] = useState([]);
  const [contentSelectPontaA, setContentSelectPontaA] = useState([]);
  const [contentSelectPontaB, setContentSelectPontaB] = useState([]);
  const [contentSelectFatoContrato, setContentSelectFatoContrato] = useState(
    []
  );
  const [contentSelectFonteEnergia, setContentSelectFonteEnergia] = useState(
    []
  );
  const [contentSelectTranche, setContentSelectTranche] = useState([]);

  const [contentSelectInternoExterno, setContentSelectInternoExterno] =
    useState([]);

  const [inputValue, setInputValue] = useState("");
  const [inputValuePontaA, setInputValuePontaA] = useState("");

  const getDataApiFilters = () => {
    api
      .get(`/filters/ponta-a`)
      .then((response) => {
        setContentSelectPontaA(response.data);
      })
      .finally(() => {
        const aux = loadingFilters;
        aux.pontaA = false;

        setLoadingFilters({ ...aux });
      });

    api
      .get(`/filters/fato-contrato`)
      .then((response) => {
        setContentSelectFatoContrato(response.data);
      })
      .finally(() => {
        const aux = loadingFilters;
        aux.fatoContrato = false;

        setLoadingFilters({ ...aux });
      });

    api
      .get(`/filters/fonte-energia`)
      .then((response) => {
        setContentSelectFonteEnergia(response.data);
      })
      .finally(() => {
        const aux = loadingFilters;
        aux.fonteEnergia = false;

        setLoadingFilters({ ...aux });
      });

    api
      .get(`/filters/tranche`)
      .then((response) => {
        setContentSelectTranche(response.data);
      })
      .finally(() => {
        const aux = loadingFilters;
        aux.tranche = false;

        setLoadingFilters({ ...aux });
      });

    api
      .get(`/filters/interno-externo`)
      .then((response) => {
        setContentSelectInternoExterno(response.data);
      })
      .finally(() => {
        const aux = loadingFilters;
        aux.internoExterno = false;

        setLoadingFilters({ ...aux });
      });

    api
      .get(`/filters/tipo-operacao`)
      .then((response) => {
        setContentSelectTipoOperacao(response.data);
      })
      .finally(() => {
        const aux = loadingFilters;
        aux.tipoOperacao = false;

        setLoadingFilters({ ...aux });
      });

    api
      .get(`/filters/razao-negocio`)
      .then((response) => {
        setContentSelectRazaoNegocio(response.data);
      })
      .finally(() => {
        const aux = loadingFilters;
        aux.razaoNegocio = false;

        setLoadingFilters({ ...aux });
      });

    api
      .get(`/filters/status`)
      .then((response) => {
        setContentSelectStatus(response.data);
      })
      .finally(() => {
        const aux = loadingFilters;
        aux.status = false;

        setLoadingFilters({ ...aux });
      });

    api
      .get(`/filters/submercado`)
      .then((response) => {
        setContentSelectSubmercado(response.data);
      })
      .finally(() => {
        const aux = loadingFilters;
        aux.submercado = false;

        setLoadingFilters({ ...aux });
      });

    api
      .get(`/filters/ponta-b`)
      .then((response) => {
        setContentSelectPontaB(response.data);
      })
      .finally(() => {
        const aux = loadingFilters;
        aux.pontaB = false;

        setLoadingFilters({ ...aux });
      });
  };

  const cleanFilters = () => {
    setFilter({
      startData: "",
      finalData: "",
      pontaA: null,
      pontaB: null,
      contract: "",
      energy: "",
      tranche: "",
      code: "",
      internoExterno: "",
      tipoOperacao: "",
      tipoFonte: "",
      razaoNegocio: "",
      submercado: "",
      status: "",
      titulo: "",
    });
  };

  useEffect(() => {
    getDataApiFilters();
  }, []);

  return (
    <>
      <Grid item md={10} sm={9} xs={12}>
        <Card style={{ height: "100%" }}>
          <Text>Filtros</Text>

          <AreaFiltersWrapper>
            <div className="inputDiv">
              <Label text="Vigência" />

              <Input
                value={filter.startData}
                onInput={(e) =>
                  setFilter({ ...filter, startData: e.target.value })
                }
                size="small"
                fullWidth
                type="month"
              />
            </div>

            <div className="inputDiv">
              <Label text="Ponta A" />

              {loadingFilters.pontaA ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "100px",
                    height: "40px",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                  }}
                >
                  <CircularProgress
                    size={24}
                    sx={{ color: "rgba(0, 0, 0, 0.23)" }}
                  />
                </Box>
              ) : (
                <Autocomplete
                  value={filter.pontaA}
                  onChange={(event, newValue) => {
                    setFilter({ ...filter, pontaA: newValue });
                  }}
                  inputValue={inputValuePontaA}
                  onInputChange={(event, newInputValue) => {
                    setInputValuePontaA(newInputValue);
                  }}
                  id="controllable-states-demo"
                  noOptionsText="Não foi encontrado"
                  options={contentSelectPontaA}
                  sx={{
                    width: "100%",
                    ".MuiInputBase-root": {
                      height: "38px!important",
                    },
                    input: {
                      fontSize: "14px!important",
                      height: "8px",
                    },
                    fieldset: { borderRadius: "100px" },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Todos" />
                  )}
                  getOptionLabel={(option) => option.label}
                />
              )}
            </div>

            <div className="inputDiv">
              <Label text="Fato do contrato" />

              {loadingFilters.fatoContrato ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "100px",
                    height: "40px",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                  }}
                >
                  <CircularProgress
                    size={24}
                    sx={{ color: "rgba(0, 0, 0, 0.23)" }}
                  />
                </Box>
              ) : (
                <SelectInput
                  size="small"
                  value={filter.contract}
                  onChange={(e) =>
                    setFilter({ ...filter, contract: e.target.value })
                  }
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="">
                    <em>Todos</em>
                  </MenuItem>

                  {contentSelectFatoContrato?.length > 0 &&
                    contentSelectFatoContrato?.map((value) => (
                      <MenuItem key={value.value} value={value.value}>
                        {value.label}
                      </MenuItem>
                    ))}
                </SelectInput>
              )}
            </div>

            <div className="inputDiv">
              <Label text="Fonte de energia" />

              {loadingFilters.fonteEnergia ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "100px",
                    height: "40px",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                  }}
                >
                  <CircularProgress
                    size={24}
                    sx={{ color: "rgba(0, 0, 0, 0.23)" }}
                  />
                </Box>
              ) : (
                <SelectInput
                  size="small"
                  displayEmpty
                  value={filter.energy}
                  onChange={(e) =>
                    setFilter({ ...filter, energy: e.target.value })
                  }
                  fullWidth
                >
                  <MenuItem value="">
                    <em>Todos</em>
                  </MenuItem>

                  {contentSelectFonteEnergia?.length > 0 &&
                    contentSelectFonteEnergia?.map((value) => (
                      <MenuItem key={value.value} value={value.value}>
                        {value.label}
                      </MenuItem>
                    ))}
                </SelectInput>
              )}
            </div>

            <div className="inputDiv">
              <Label text="Tranche" />

              {loadingFilters.tranche ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "100px",
                    height: "40px",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                  }}
                >
                  <CircularProgress
                    size={24}
                    sx={{ color: "rgba(0, 0, 0, 0.23)" }}
                  />
                </Box>
              ) : (
                <SelectInput
                  size="small"
                  displayEmpty
                  value={filter.tranche}
                  onChange={(e) =>
                    setFilter({ ...filter, tranche: e.target.value })
                  }
                  fullWidth
                >
                  <MenuItem value="">
                    <em>Todos</em>
                  </MenuItem>

                  {contentSelectTranche?.length > 0 &&
                    contentSelectTranche?.map((value) => (
                      <MenuItem key={value.value} value={value.value}>
                        {value.label}
                      </MenuItem>
                    ))}
                </SelectInput>
              )}
            </div>

            <div className="inputDiv">
              <Label text="Interno/Externo" />

              {loadingFilters?.internoExterno ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "100px",
                    height: "40px",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                  }}
                >
                  <CircularProgress
                    size={24}
                    sx={{ color: "rgba(0, 0, 0, 0.23)" }}
                  />
                </Box>
              ) : (
                <SelectInput
                  size="small"
                  value={filter.internoExterno}
                  onChange={(e) =>
                    setFilter({ ...filter, internoExterno: e.target.value })
                  }
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="">
                    <em>Todos</em>
                  </MenuItem>

                  {contentSelectInternoExterno?.length > 0 &&
                    contentSelectInternoExterno?.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                </SelectInput>
              )}
            </div>

            <div className="inputDiv">
              <Label text="Tipo de operação" />

              {loadingFilters?.tipoOperacao ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "100px",
                    height: "40px",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                  }}
                >
                  <CircularProgress
                    size={24}
                    sx={{ color: "rgba(0, 0, 0, 0.23)" }}
                  />
                </Box>
              ) : (
                <SelectInput
                  size="small"
                  value={filter.tipoOperacao}
                  onChange={(e) =>
                    setFilter({ ...filter, tipoOperacao: e.target.value })
                  }
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="">
                    <em>Todos</em>
                  </MenuItem>

                  {contentSelectTipoOperacao?.length > 0 &&
                    contentSelectTipoOperacao?.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                </SelectInput>
              )}
            </div>

            <div className="inputDiv">
              <Label text="Razão Negócio" />

              {loadingFilters?.razaoNegocio ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "100px",
                    height: "40px",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                  }}
                >
                  <CircularProgress
                    size={24}
                    sx={{ color: "rgba(0, 0, 0, 0.23)" }}
                  />
                </Box>
              ) : (
                <SelectInput
                  size="small"
                  value={filter.razaoNegocio}
                  onChange={(e) =>
                    setFilter({ ...filter, razaoNegocio: e.target.value })
                  }
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="">
                    <em>Todos</em>
                  </MenuItem>

                  {contentSelectRazaoNegocio?.length > 0 &&
                    contentSelectRazaoNegocio?.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                </SelectInput>
              )}
            </div>

            <div className="inputDiv">
              <Label text="Submercado" />

              {loadingFilters?.submercado ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "100px",
                    height: "40px",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                  }}
                >
                  <CircularProgress
                    size={24}
                    sx={{ color: "rgba(0, 0, 0, 0.23)" }}
                  />
                </Box>
              ) : (
                <SelectInput
                  size="small"
                  displayEmpty
                  value={filter.submercado}
                  onChange={(e) =>
                    setFilter({ ...filter, submercado: e.target.value })
                  }
                  fullWidth
                >
                  <MenuItem value="">
                    <em>Todos</em>
                  </MenuItem>

                  {contentSelectSubmercado?.length > 0 &&
                    contentSelectSubmercado?.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                </SelectInput>
              )}
            </div>

            <div className="inputDiv">
              <Label text="Status" />

              {loadingFilters?.status ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "100px",
                    height: "40px",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                  }}
                >
                  <CircularProgress
                    size={24}
                    sx={{ color: "rgba(0, 0, 0, 0.23)" }}
                  />
                </Box>
              ) : (
                <SelectInput
                  size="small"
                  displayEmpty
                  value={filter.status}
                  onChange={(e) =>
                    setFilter({ ...filter, status: e.target.value })
                  }
                  fullWidth
                >
                  <MenuItem value="">
                    <em>Todos</em>
                  </MenuItem>

                  {contentSelectStatus?.length > 0 &&
                    contentSelectStatus?.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                </SelectInput>
              )}
            </div>

            <div className="inputDiv" style={{ alignSelf: "flex-end" }}>
              <Label text="Ponta B" />

              {loadingFilters?.pontaB ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "100px",
                    height: "40px",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                  }}
                >
                  <CircularProgress
                    size={24}
                    sx={{ color: "rgba(0, 0, 0, 0.23)" }}
                  />
                </Box>
              ) : (
                <Autocomplete
                  value={filter.pontaB}
                  onChange={(event, newValue) => {
                    setFilter({ ...filter, pontaB: newValue });
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  id="controllable-states-demo"
                  noOptionsText="Não foi encontrado"
                  options={contentSelectPontaB}
                  sx={{
                    width: "100%",
                    ".MuiInputBase-root": {
                      height: "38px!important",
                    },
                    input: {
                      fontSize: "14px!important",
                      height: "8px",
                    },
                    fieldset: { borderRadius: "100px" },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Todos" />
                  )}
                  getOptionLabel={(option) => option.label}
                />
              )}
            </div>

            <div className="inputDiv" style={{ alignSelf: "flex-end" }}>
              <Label text="Título" />

              <Input
                size="small"
                value={filter.titulo}
                onChange={(e) =>
                  setFilter({ ...filter, titulo: e.target.value })
                }
                fullWidth
                placeholder="Título do contrato"
              />
            </div>

            <div className="inputDiv" style={{ alignSelf: "flex-end" }}>
              <Label text="Código do contrato" />

              <Input
                size="small"
                value={filter.code}
                onChange={(e) => setFilter({ ...filter, code: e.target.value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                placeholder="Código do contrato"
              />
            </div>

            <div className="inputDiv" style={{ alignSelf: "flex-end" }}>
              <Btn
                onClick={() => {
                  setPage(1);
                  getDataApiContracts(1);
                }}
                fullWidth
                sx={{
                  color: "#FFF!important",
                  backgroundColor: "var(--main-color)!important",
                }}
              >
                <span>Filtrar contratos</span>
                <ArrowForwardIcon />
              </Btn>
            </div>

            <div
              className="lastDivFilter inputDiv"
              style={{ alignSelf: "flex-end" }}
            >
              <Btn
                onClick={() => cleanFilters()}
                fullWidth
                sx={{
                  color: "black!important",
                  backgroundColor: "white!important",
                  border: "1px solid #606060!important",
                }}
              >
                <span>Limpar filtros</span>
                <RestartAltIcon />
              </Btn>
            </div>

            <div style={{ alignSelf: "flex-end" }} />
          </AreaFiltersWrapper>
        </Card>
      </Grid>
    </>
  );
}
