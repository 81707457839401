import { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Skeleton,
} from "@mui/material";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import MainModal from "../../MainModal";
import api from "../../../services/api";
import { snackbarActive } from "../../../store/modules/snackbar/action";
import { Description, Subtitle, Values } from "./styled";

export default function ModalHistoricAlcada({ open, setOpen, alcadaId }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const [infos, setInfos] = useState(null);
  const [historic, setHistoric] = useState([]);

  const apiGetHistoric = () => {
    setLoading(true);

    setInfos(null);
    setHistoric([]);

    api
      .get(`/alcadas/${alcadaId}/historico`)
      .then((response) => {
        setInfos(response.data.info);
        setHistoric(response.data.historico);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (open && alcadaId) {
      apiGetHistoric();
    }

    return () => {
      setLoading(true);
    };
  }, [open, alcadaId]);

  return (
    <MainModal open={open} setOpen={setOpen} width="700px">
      <Box
        sx={{
          position: "absolute",
          top: "8px",
          right: "8px",
        }}
      >
        <IconButton title="Fechar" onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Grid mb={0} container>
        <Grid item md={4}>
          {loading ? (
            <Skeleton variant="rounded" height={17} width="70%" />
          ) : (
            <Values>
              <b>ID:</b> {infos?.id}
            </Values>
          )}
        </Grid>

        <Grid item md={4}>
          {loading ? (
            <Skeleton variant="rounded" height={17} width="70%" />
          ) : (
            <Values>
              <b>Status:</b> {infos?.status}
            </Values>
          )}
        </Grid>
      </Grid>

      <Grid
        mt={0}
        container
        sx={{
          my: "16px",
        }}
      >
        <Grid item md={4}>
          {loading ? (
            <Skeleton variant="rounded" height={17} width="70%" />
          ) : (
            <Values>
              <b>Total de contratos:</b> {infos?.total_contratos}
            </Values>
          )}
        </Grid>

        <Grid item md={4}>
          {loading ? (
            <Skeleton variant="rounded" height={17} width="70%" />
          ) : (
            <Values>
              <b>Valor total:</b> {infos?.valor_total}
            </Values>
          )}
        </Grid>

        <Grid item md={4}>
          {loading ? (
            <Skeleton variant="rounded" height={17} width="70%" />
          ) : (
            <Values>
              <b>Criado em:</b> {infos?.criado_em}
            </Values>
          )}
        </Grid>
      </Grid>

      <Divider />

      <Box
        sx={{
          mt: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        {historic?.length > 0 &&
          historic?.map((item, index) => (
            <Box
              key={item.id}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "40px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  "&:before": {
                    display: index + 1 === historic?.length ? "none" : "flex",
                    content: '""',
                    position: "absolute",
                    opacity: "0.3",
                    top: "28px",
                    bottom: "0",
                    height: "28px",
                    width: "1px",
                    backgroundColor: "#1A76D3",
                    borderRadius: "1000px",
                  },
                }}
              >
                {item.status === "DONE" ? (
                  <CheckCircleIcon
                    sx={{
                      color: "#1A76D3",
                    }}
                  />
                ) : null}

                {item.status === "PROGRESS" ? (
                  <CircularProgress
                    size={20}
                    sx={{
                      color: "#1A76D3",
                    }}
                  />
                ) : null}

                {item.status === "WAITING" ? (
                  <Box
                    sx={{
                      backgroundColor: "#1A76D3",
                      width: "20px",
                      height: "20px",
                      borderRadius: "1000px",
                    }}
                  />
                ) : null}
              </Box>

              <Box>
                <Subtitle>{item.titulo}</Subtitle>
                <Description>{item.descricao}</Description>
              </Box>
            </Box>
          ))}
      </Box>
    </MainModal>
  );
}
