import { useDispatch } from "react-redux";

import MainModal from "../../../../components/MainModal";
import api from "../../../../services/api";
import { snackbarActive } from "../../../../store/modules/snackbar/action";
import { loadingFullActive } from "../../../../store/modules/loadingFull/action";
import { ModalButtonArea } from "./styled";

export default function ModalSendProtheus({
  open,
  setOpen,
  idSelectProtheus,
  setSelectedsIdsProtheus,
  getDataApiContracts,
}) {
  const dispatch = useDispatch();

  const apiProtheus = () => {
    dispatch(loadingFullActive(true));

    setOpen(false);

    api
      .post(`/contracts/${idSelectProtheus}/protheus`, null)
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message ||
              "Contrato enviado para o Protheus com sucesso!",
          })
        );

        getDataApiContracts();

        setSelectedsIdsProtheus([]);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  return (
    <MainModal width={450} open={open} setOpen={setOpen}>
      <h2 style={{ margin: 0 }}>Confirmação</h2>

      <p>Deseja realmente enviar esse contrato para o Protheus?</p>

      <ModalButtonArea>
        <button onClick={() => apiProtheus()}>Enviar</button>

        <button onClick={() => setOpen(false)}>Cancelar</button>
      </ModalButtonArea>
    </MainModal>
  );
}
