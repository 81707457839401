import styled from "styled-components";

export const ModalButtonArea = styled.div`
  gap: 10px;
  display: flex;
  justify-content: flex-end;
  margin-top: 1.7em;

  button {
    background: grey;
    border: 0;
    color: white;
    font-size: 14px;
    border-radius: 50px;
    padding: 0.8em 1.6em;

    :hover {
      opacity: 0.6;
    }

    :active {
      outline: 3px solid lightgrey;
    }

    :first-child {
      background: var(--main-color);
    }
  }
`;

export const BtnAction = styled.span`
  background-color: #da45f8;
  color: white;
  border-radius: 50px;
  padding: 0.5em 1em;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  justify-content: center;
  width: max-content;
  font-size: 13px;

  :hover {
    opacity: 0.6;
  }

  :active {
    outline: 1px solid lightgrey;
  }
`;

export const MessageError = styled.span`
  color: #d32f2f;
  font-size: 12px;
`;

export const InfoArea = styled.div`
  p {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    color: var(--text-color);
  }

  p:nth-child(2n) {
    font-weight: 400;
  }
`;

export const CodeArea = styled.div`
  h5 {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: var(--text-color);
  }

  p {
    padding-top: 0.2em;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 20px;
    margin: 0;
    color: var(--text-color);
  }
`;

export const DataArea = styled.div`
  p {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: var(--text-color);
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #757575;
  }
`;

export const ActionArea = styled.div`
  margin-left: 2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  div {
    display: flex;
    border-radius: 50px;
    margin-right: 0.3em;
    background: #b0a5f3;
    padding: 0.5em;
    color: white;

    svg {
      font-size: 17px;
    }
  }

  :hover {
    opacity: 0.6;
  }
`;

export const LabelOptions = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin: 0;
  margin-top: 1em;
  color: var(--text-color);
`;

export const AreaValidity = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #cfcfcf;
  border-radius: 50px;
  padding: 1em;
  position: relative;
  width: max-content;

  label {
    position: absolute;
    top: 0;
    padding: 0.3em;
    transform: translateY(-12px);
    text-align: center;
    background: white;
  }
`;

export const ModalUploadSazo = styled.div`
  display: flex;
  flex-direction: column;

  div:first-child {
    background-color: #da45f8;
    display: flex;
    padding: 0.6em;
    align-self: flex-start;
    border-radius: 50px;
  }

  div:last-child {
    display: flex;
    align-self: flex-start;
  }

  a {
    color: #da45f8;
  }

  a:hover {
    opacity: 0.6;
  }

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: var(--text-color);
    margin: 0;
    padding-top: 1em;
    padding-bottom: 0.5em;
  }

  p {
    padding-bottom: 1.5em;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-color);
  }

  button {
    background: #da45f8;
    border-radius: 22px;
    padding: 0.6em 1.5em;
    color: white;
    display: flex;
    align-items: center;
    gap: 15px;
    border: 0;
    font-size: 15px;

    svg {
      font-size: 19px;
    }
  }

  button:hover {
    opacity: 0.6;
  }
`;
