import styled from "styled-components";

export const Area = styled.aside`
  background: #000044;
  display: flex;
  flex-direction: column;
  position: fixed;
  padding-top: ${(props) => (props.isMenuMinimized ? "0" : "10px")};
  max-height: calc(100% - 70px);
  overflow-y: scroll;
  overscroll-behavior: contain;
  height: 100%;
  width: ${(props) =>
    props.isMenuMinimized
      ? "var(--sidemenu-width-minimized)"
      : "var(--sidemenu-width)"};
  padding-bottom: 5rem;

  ::-webkit-scrollbar {
    width: 0;
  }

  :hover {
    ::-webkit-scrollbar {
      width: 12px;
    }
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #000044;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 3px solid transparent;
    background-clip: content-box !important;
    background: #aaaaaa;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: lightgrey;
  }

  ${(props) =>
    props.isMenuMinimized && ":hover{ ::-webkit-scrollbar { width: 0; } } "};

  @media (min-height: 830px) {
    :hover {
      ::-webkit-scrollbar {
        width: 0;
      }
    }
  }

  @media (max-width: 820px) {
    display: none;
  }
`;

export const MenuDivider = styled.div`
  ${(props) => props.isMenuMinimized && "display: none;"};

  h5 {
    margin: 0;
    cursor: default;
    padding: 0.7em 1.4em;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMenuMinimized ? "column" : "row")};
  padding: ${(props) => (props.isMenuMinimized ? ".9em 0" : "0 1.4em")};
  cursor: pointer;

  :hover {
    background-color: lightgrey;
  }

  ${(props) =>
    props.active &&
    "p{ color: #3FC2DA!important; } svg{ color: #3FC2DA!important } "}
`;

export const IconArea = styled.div`
  align-self: center;
  display: flex;

  svg {
    color: white;
    font-size: 22px;
  }
`;

export const TextoArea = styled.div`
  padding-left: ${(props) => (props.isMenuMinimized ? ".1em" : "1em")};

  span {
    width: max-content;
  }

  p {
    ${(props) =>
      props.isMenuMinimized &&
      "margin-top: 0; margin-bottom: 0;word-spacing: 11em;"};
    font-weight: 400;
    font-size: ${(props) => (props.isMenuMinimized ? "9px" : "13px")};
    line-height: 14px;
    color: #ffffff;
    text-align: ${(props) => (props.isMenuMinimized ? "center" : "left")};
    word-break: break-all;
  }
`;
