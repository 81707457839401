import { useEffect, useState } from "react";
import { Grid, MenuItem, Pagination } from "@mui/material";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import Card from "../../components/Card";
import MainTemplate from "../../components/MainTemplate";
import { BtnAction, ModalButtonArea, MessageError, TitleList } from "./styled";
import Table from "../../components/Table";
import Button from "../../components/Button";
import MainModal from "../../components/MainModal";
import Label from "../../components/Label";
import Input from "../../components/Input";
import SelectInput from "../../components/SelectInput";
import api from "../../services/api";
import { loadingFullActive } from "../../store/modules/loadingFull/action";
import { snackbarActive } from "../../store/modules/snackbar/action";

export default function CCEE() {
  const dispatch = useDispatch();

  const [contentSelectCompany, setContentSelectCompany] = useState([]);
  const [contentSelectEnergy, setContentSelectEnergy] = useState([]);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [contentTable, setContentTable] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [open, setOpen] = useState(false);

  const [idCCEE, setIdCCEE] = useState();

  const [search, setSearch] = useState("");

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const headerTable = [
    "ID",
    "Sigla Cliq CCEE",
    "Código Cliq CCEE",
    "Empresa",
    "Fonte de energia",
    "Ações",
  ];

  const getDataApiCCEE = async (page = 1) => {
    setLoading(true);

    await api
      .get(`/ccee?page=${page}&q=${search}`)
      .then((response) => {
        let list = response.data.data;

        for (let i = 0; i < list.length; i++) {
          list[i].acoes = (
            <BtnAction
              onClick={() => {
                setIdCCEE(list[i].id);
                setOpen(true);
              }}
            >
              Excluir
            </BtnAction>
          );
        }

        setContentTable(list);
        setLastPage(response.data.last_page);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const apiCreateCCEE = async (data) => {
    dispatch(loadingFullActive(true));

    await api
      .post("/ccee", {
        company_id: data.empresa,
        energy_source_id: data.fonteEnergia,
        sigla_cliq: data.siglaCCEE,
        codigo_cliq: data.codigoCCEE,
      })
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message ||
              "Credencial CCEE adicionado com sucesso!",
          })
        );

        reset();
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  const apiDeleteCCEE = async () => {
    dispatch(loadingFullActive(true));

    await api
      .delete(`/ccee/${idCCEE}`)
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message ||
              "Credencial CCEE removida com sucesso!",
          })
        );
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        setOpen(false);
        getDataApiCCEE(page);
        dispatch(loadingFullActive(false));
      });
  };

  const getDataContentSelects = async () => {
    await api
      .get("/companies?no-pagination")
      .then((response) => {
        setContentSelectCompany(response.data);
      })
      .catch((err) => {
        console.log("pegar conteudo select companhias", err);
      });

    await api
      .get("/filters/fonte-energia")
      .then((response) => {
        setContentSelectEnergy(response.data);
      })
      .catch((err) => {
        console.log("pegar conteudo select fonte-energia", err);
      });
  };

  const handlePagination = (page) => {
    setPage(page);
    getDataApiCCEE(page);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    handlePagination(1);
  };

  useEffect(() => {
    getDataApiCCEE();
    getDataContentSelects();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainTemplate title="Adicionar credenciais CCEE">
        <Card>
          <Grid container spacing={2}>
            <Grid item md={3} xs={6}>
              <Label text="Empresa" />

              <Controller
                name="empresa"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <SelectInput
                    {...field}
                    ref={ref}
                    error={errors.empresa ? true : false}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {contentSelectCompany?.map((value) => (
                      <MenuItem key={value.ID} value={value.ID}>
                        {value.Nome}
                      </MenuItem>
                    ))}
                  </SelectInput>
                )}
              />

              {errors.fatoContrato && (
                <MessageError>Campo necessário</MessageError>
              )}
            </Grid>
            <Grid item md={3} xs={6}>
              <Label text="Fonte de energia" />

              <Controller
                name="fonteEnergia"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <SelectInput
                    {...field}
                    ref={ref}
                    error={errors.fonteEnergia ? true : false}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {contentSelectEnergy?.map((value) => (
                      <MenuItem key={value.value} value={value.value}>
                        {value.label}
                      </MenuItem>
                    ))}
                  </SelectInput>
                )}
              />

              {errors.fonteEnergia && (
                <MessageError>Campo necessário</MessageError>
              )}
            </Grid>
          </Grid>

          <Grid mt={0} container spacing={2}>
            <Grid item md={3} xs={6}>
              <Label text="Sigla Cliq CCEE" />

              <Controller
                name="siglaCCEE"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <Input
                    {...field}
                    ref={ref}
                    error={errors.siglaCCEE ? true : false}
                    fullWidth
                    type="text"
                    placeholder="Digite a sigla"
                    variant="outlined"
                  />
                )}
              />

              {errors.siglaCCEE && (
                <MessageError>Campo necessário</MessageError>
              )}
            </Grid>
            <Grid item md={3} xs={6}>
              <Label text="Código Cliq CCEE" />

              <Controller
                name="codigoCCEE"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <Input
                    {...field}
                    ref={ref}
                    error={errors.codigoCCEE ? true : false}
                    fullWidth
                    type="text"
                    placeholder="Digite o código"
                    variant="outlined"
                  />
                )}
              />

              {errors.codigoCCEE && (
                <MessageError>Campo necessário</MessageError>
              )}
            </Grid>
          </Grid>

          <Button
            text="Adicionar"
            onClick={handleSubmit(apiCreateCCEE)}
            sx={{ mt: 4, mb: 1 }}
          />
        </Card>

        <TitleList>Credenciais CCEE</TitleList>

        <Card style={{ padding: 0 }}>
          <Grid px={2} pt={2} mb={2} container>
            <Grid item md={4} sm={6} xs={12}>
              <Label text="Código do contrato" />

              <Input
                size="small"
                value={search}
                onChange={handleSearch}
                placeholder="Buscar por sigla, código, empresa"
                fullWidth
              />
            </Grid>
          </Grid>

          <Table header={headerTable} loading={loading}>
            {contentTable?.length > 0 &&
              contentTable?.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item['"Sigla Cliq CCEE"']}</td>
                  <td>{item['"Código Cliq CCEE"']}</td>
                  <td>{item["Empresa"]}</td>
                  <td>{item["Fonte de energia"]}</td>
                  <td>{item.acoes}</td>
                </tr>
              ))}
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              paddingBottom: 20,
            }}
          >
            <Pagination
              count={lastPage}
              page={page}
              onChange={(event, value) => handlePagination(value)}
            />
          </div>
        </Card>

        <MainModal width={450} open={open} setOpen={setOpen}>
          <h2 style={{ margin: 0 }}>Confirmação</h2>
          <p>Deseja realmente excluir essa Credencial CCEE?</p>

          <ModalButtonArea>
            <button onClick={() => apiDeleteCCEE()}>Confirmar</button>
            <button onClick={() => setOpen(false)}>Cancelar</button>
          </ModalButtonArea>
        </MainModal>
      </MainTemplate>
    </>
  );
}
