import { useDispatch } from "react-redux";

import MainModal from "../../../../components/MainModal";
import api from "../../../../services/api";
import { snackbarActive } from "../../../../store/modules/snackbar/action";
import { loadingFullActive } from "../../../../store/modules/loadingFull/action";
import { ModalButtonArea } from "./styled";

export default function ModalApproveContract({
  open,
  setOpen,
  aproveContractData,
  setAproveContractData,
  getDataApiContracts,
}) {
  const dispatch = useDispatch();

  const apiAproveContract = () => {
    dispatch(loadingFullActive(true));

    setOpen(false);

    api
      .post(`/contracts/${aproveContractData.id}/aprove`, {
        cod_interno: aproveContractData.codeIntern,
      })
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message || "Contrato aprovado com sucesso!",
          })
        );
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        setAproveContractData({
          id: "",
          codeIntern: "",
        });

        getDataApiContracts();

        dispatch(loadingFullActive(false));
      });
  };

  return (
    <MainModal width={450} open={open} setOpen={setOpen}>
      <h2 style={{ margin: 0 }}>Confirmação</h2>

      <p>Deseja realmente aprovar esse contrato?</p>

      <ModalButtonArea>
        <button onClick={() => apiAproveContract()}>Aprovar</button>

        <button onClick={() => setOpen(false)}>Cancelar</button>
      </ModalButtonArea>
    </MainModal>
  );
}
