import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

if (localStorage?.getItem("user")) {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user) {
    api.defaults.headers["Authorization"] = `Bearer ${user?.token}`;
  }
}

export default api;
