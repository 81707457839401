import styled from "styled-components";

export const TableParams = styled.table`
  width: 100%;
  margin-top: 1em;

  td {
    padding: 1em 0;
    border-bottom: 1px solid #f2f2f2 !important;

    :first-child {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #2e2e2e;
    }

    :nth-child(3) {
      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #003366;
        background: #66b3ff;
        border-radius: 16px;
        text-align: center;
        padding: 0.6em 1.2em;
        cursor: pointer;
      }

      span:hover {
        opacity: 0.6;
      }
    }

    :nth-child(4) {
      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #003366;
        background: #66b3ff;
        border-radius: 16px;
        text-align: center;
        padding: 0.7em 1.4em;
        cursor: pointer;
      }

      span:hover {
        opacity: 0.6;
      }
    }
  }
`;

export const Badge = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => (props.ativo ? "#0080ff" : "#a49500")};
  background: ${(props) => (props.ativo ? "#cce6ff" : "#fff6a7")};
  border-radius: 16px;
  text-align: center;
  padding: 0.5em 1em;
`;

export const Text = styled.h3`
  margin: 0;
  padding-bottom: 1em;
`;

export const ModalButtonArea = styled.div`
  gap: 10px;
  display: flex;
  justify-content: flex-end;
  margin-top: 1.7em;

  button {
    background: grey;
    border: 0;
    color: white;
    font-size: 14px;
    border-radius: 6px;
    padding: 0.8em 1.6em;

    :hover {
      opacity: 0.6;
    }

    :active {
      outline: 3px solid lightgrey;
    }

    :first-child {
      background: var(--main-color);
    }
  }
`;

export const MessageError = styled.span`
  color: #d32f2f;
  font-size: 12px;
`;
