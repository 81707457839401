import { useEffect, useState } from "react";
import { Grid, InputAdornment, MenuItem } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import { Controller } from "react-hook-form";
import { useDispatch } from "react-redux";

import Label from "../../../../components/Label";
import Card from "../../../../components/Card";
import { Btn, Input, SelectInput, Text } from "./styled";
import api from "../../../../services/api";
import { snackbarActive } from "../../../../store/modules/snackbar/action";

export default function Filters({
  control,
  handleCleanFilters,
  handlePagination,
}) {
  const [contentStatus, setContentStatus] = useState([]);

  const dispatch = useDispatch();

  const apiGetAlcadasStatus = () => {
    api
      .get("/filters/alcada-status")
      .then((response) => {
        setContentStatus(response.data);
      })
      .catch((error) => {
        dispatch(snackbarActive("error", "Erro ao buscar status"));
      });
  };

  useEffect(() => {
    apiGetAlcadasStatus();
  }, []);

  return (
    <Card style={{ height: "100%" }}>
      <Text>Filtros</Text>

      <Grid container spacing={2}>
        <Grid item md={2} alignSelf={"end"}>
          <Label text="Vigência (mês)" />

          <Controller
            name="dateMonth"
            defaultValue={""}
            control={control}
            render={({ field }) => (
              <Input {...field} size="small" fullWidth type="month" />
            )}
          />
        </Grid>

        <Grid item md={2} alignSelf={"end"}>
          <Label text="Status" />

          <Controller
            name="status"
            defaultValue={""}
            control={control}
            render={({ field }) => (
              <SelectInput {...field} size="small" displayEmpty fullWidth>
                <MenuItem value={""}>
                  <em>Todos os status</em>
                </MenuItem>

                {contentStatus?.length > 0 &&
                  contentStatus?.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
              </SelectInput>
            )}
          />
        </Grid>

        <Grid item md={2} alignSelf={"end"}>
          <Label text="Código da alçada" />

          <Controller
            name="alcadaId"
            defaultValue={""}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                placeholder="Código da alçada"
              />
            )}
          />
        </Grid>

        <Grid item md={2} alignSelf={"end"}>
          <Btn
            onClick={() => handlePagination(1)}
            fullWidth
            type="submit"
            style={{
              color: "white",
              backgroundColor: "var(--main-color)",
            }}
          >
            <span>Filtrar alçadas</span>
            <ArrowForwardIcon />
          </Btn>
        </Grid>

        <Grid item md={2} alignSelf={"end"}>
          <Btn
            onClick={() => handleCleanFilters()}
            fullWidth
            style={{
              color: "black",
              backgroundColor: "white",
              border: "1px solid #606060",
            }}
          >
            <span>Limpar filtros</span>
            <RestartAltIcon />
          </Btn>
        </Grid>
      </Grid>
    </Card>
  );
}
