import { useEffect } from "react";
import { Box, Divider, Grid, IconButton, Tooltip } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import NumberFormat from "react-number-format";
import CloseIcon from "@mui/icons-material/Close";

import MainModal from "../../../../components/MainModal";
import api from "../../../../services/api";
import { Description, ModalButtonArea, Text, Title } from "./styled";
import Input from "../../../../components/Input";
import { loadingFullActive } from "../../../../store/modules/loadingFull/action";
import { snackbarActive } from "../../../../store/modules/snackbar/action";

export default function ModalEditarPrecoEnergia({
  open,
  setOpen,
  contract,
  getDataApiContracts,
}) {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const apiEditPrecoEnergia = async (data) => {
    dispatch(loadingFullActive(true));

    const newValor = {
      codigo_interno_contrato: contract["Código interno"],
      data_vigencia_inicial_contrato: contract["Inicio suprimento"],
      preco_atual_novo: data.precoEnergia.replaceAll(".", "").replace(",", "."),
    };

    await api
      .post(`/contracts/period`, newValor)
      .then((response) => {
        getDataApiContracts();

        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message ||
              "Preço da energia atualizada com sucesso!",
          })
        );

        setOpen(false);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message ||
              "Não foi possível atualizar o preço da energia, tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  useEffect(() => {
    setValue("precoEnergia", contract["Preço da energia"]?.replace(".", ","));
  }, [contract]);

  return (
    <>
      <MainModal open={open} setOpen={setOpen}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: 3 }}>
            <Text style={{ margin: 0 }}>
              <b>Cod interno</b>: {contract["Código interno"]}
            </Text>

            <Text style={{ margin: 0 }}>
              <b>Vigência inicial contrato</b>: {contract["Inicio suprimento"]}
            </Text>
          </Box>
          <Tooltip disableInteractive>
            <IconButton onClick={() => setOpen(false)} title="Fechar">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Divider sx={{ paddingTop: 2, mb: 2 }} />

        <Title>Alteração de preço de energia</Title>

        <Description>Deseja alterar o preço de energia inicial?</Description>

        <form onSubmit={handleSubmit(apiEditPrecoEnergia)}>
          <Grid container spacing={2}>
            <Grid item md={8} xs={12}>
              <Controller
                rules={{ required: true }}
                control={control}
                name="precoEnergia"
                render={({ field: { value, onChange, onBlur }, ref }) => (
                  <NumberFormat
                    thousandSeparator="."
                    decimalScale={2}
                    allowNegative={false}
                    decimalSeparator=","
                    fixedDecimalScale
                    sx={{ mt: 2 }}
                    type="text"
                    getInputRef={ref}
                    id="precoEnergia"
                    name="precoEnergia"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={errors.precoEnergia ? true : false}
                    fullWidth
                    placeholder="00,00"
                    customInput={Input}
                  />
                )}
              />
            </Grid>
          </Grid>

          <ModalButtonArea>
            <button type="submit">Editar</button>

            <button type="button" onClick={() => setOpen(false)}>
              Cancelar
            </button>
          </ModalButtonArea>
        </form>
      </MainModal>
    </>
  );
}
