import { Button } from "@mui/material";
import styled from "styled-components";

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: var(--text-color);

  margin-top: 0;
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: var(--text-color);

  span {
    color: var(--main-color);
  }
`;

export const ButtonCustom = styled(Button)`
  height: 40px !important;
  padding: 0 20px !important;
  border-radius: 1000px !important;
  background-color: #0080ff !important;
  color: #fff !important;
  text-transform: none !important;
  flex-shrink: 0 !important;

  &:hover {
    background-color: #005cb2 !important;
  }
`;

export const ButtonCustomCancel = styled(Button)`
  height: 40px !important;
  padding: 0 20px !important;
  border-radius: 1000px !important;
  background-color: #dddddd !important;
  color: grey !important;
  text-transform: none !important;
  flex-shrink: 0 !important;
  font-weight: 600 !important;

  &:hover {
    opacity: 0.8;
  }
`;
