import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";

import { TableCustom } from "./styled";

export default function Table({
  header,
  children,
  loading,
  scrollTopActive,
  height = "auto",
}) {
  const [widthTable, setWidthTable] = useState(0);

  useEffect(() => {
    if (scrollTopActive) {
      let wrapper1 = document.getElementById("wrapper1");
      let wrapper2 = document.getElementById("wrapper2");

      function controlScrollDouble() {
        wrapper1.onscroll = function () {
          wrapper2.scrollLeft = wrapper1.scrollLeft;
        };
        wrapper2.onscroll = function () {
          wrapper1.scrollLeft = wrapper2.scrollLeft;
        };

        setWidthTable(document.getElementById("tableCustom").offsetWidth);
      }

      new ResizeObserver(controlScrollDouble).observe(wrapper2);
    }
  }, []);

  return (
    <>
      {scrollTopActive && (
        <div
          id="wrapper1"
          style={{
            width: "98.8%",
            border: "none 0px RED",
            overflowX: "scroll",
            overflowY: "hidden",
            height: "20px",
          }}
        >
          <div
            id="div1"
            style={{
              width: `${widthTable}px`,
              height: "20px",
            }}
          ></div>
        </div>
      )}

      <div
        id="wrapper2"
        style={{
          overflowX: "auto",

          height: height,
          maxHeight: scrollTopActive ? "70vh" : "unset",
        }}
      >
        <TableCustom id="tableCustom">
          <thead>
            <tr>
              {header?.map((value) => (
                <th key={value}>{value}</th>
              ))}
            </tr>
          </thead>

          {!loading && <tbody>{children}</tbody>}
        </TableCustom>
      </div>

      {!children && !loading && (
        <div
          style={{
            marginTop: 100,
            paddingBottom: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            color: "grey",
            fontSize: "16px",
          }}
        >
          <p>Não foi encontrado nenhum item.</p>
        </div>
      )}

      {loading && (
        <div
          style={{
            marginTop: 100,
            paddingBottom: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress sx={{ color: "var(--main-color)" }} />
        </div>
      )}
    </>
  );
}
