import { Button, Select, TextField } from "@mui/material";
import styled from "styled-components";

export const Btn = styled(Button)`
  border-radius: 22px !important;
  display: flex;
  width: max-content !important;
  min-width: 100% !important;
  text-transform: none !important;

  span {
    padding-right: 0.5em;
    text-transform: none;
  }

  :hover {
    opacity: 0.6;
  }
`;

export const SelectInput = styled(Select)`
  font-size: 14px;

  fieldset {
    border-radius: 55px;
  }

  em {
    color: #00000061 !important;
    font-weight: 300 !important;
    font-style: normal !important;
  }
`;

export const Input = styled(TextField)`
  fieldset {
    border-radius: 55px !important;
  }

  input {
    font-size: 14px;
  }
`;

export const Text = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: var(--text-color);
  margin: 0;
  padding-bottom: 0.7em;
`;
