import { SelectI } from "./styled";

export default function SelectInput(props) {
  return (
    <SelectI
      sx={{
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--main-color)",
        },
      }}
      {...props}
    />
  );
}
