import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { Btn } from "./styled";

export default function Button(props) {
  return (
    <Btn {...props}>
      <span>{props.text}</span>
      <ArrowForwardIcon />
    </Btn>
  );
}
