import { useEffect, useState } from "react";
import { Grid, MenuItem, Pagination } from "@mui/material";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import MainTemplate from "../../components/MainTemplate";
import Card from "../../components/Card";
import { BtnAction, ModalButtonArea, MessageError, TitleList } from "./styled";
import Table from "../../components/Table";
import Button from "../../components/Button";
import MainModal from "../../components/MainModal";
import Label from "../../components/Label";
import Input from "../../components/Input";
import SelectInput from "../../components/SelectInput";
import api from "../../services/api";
import { loadingFullActive } from "../../store/modules/loadingFull/action";
import { snackbarActive } from "../../store/modules/snackbar/action";

export default function Holidays() {
  const dispatch = useDispatch();

  const [holidayId, setHolidayId] = useState("");
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [loadingTable, setLoadingTable] = useState(false);
  const [contentTable, setContentTable] = useState([]);
  const [open, setOpen] = useState(false);

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const headerTable = ["ID", "Data", "Dia da semana", "Feriado", "Ações"];

  const getDataApiHolidays = async (activePage) => {
    setLoadingTable(true);

    await api
      .get(`/holidays`, {
        params: { page: activePage || page },
      })
      .then((response) => {
        let listHolidays = response.data.data;

        for (let i = 0; i < listHolidays.length; i++) {
          listHolidays[i].acoes = (
            <BtnAction onClick={() => openModalDelete(listHolidays[i].id)}>
              Excluir
            </BtnAction>
          );
        }

        setContentTable(listHolidays);
        setLastPage(response.data.last_page);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        setLoadingTable(false);
      });
  };

  const apiCreateHoliday = async (data) => {
    dispatch(loadingFullActive(true));

    await api
      .post("/holidays", {
        date: data.data,
        week_day: data.diaSemana,
        name: data.feriado,
      })
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message || "Feriado adicionado com sucesso!",
          })
        );
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        getDataApiHolidays(page);
        reset();
        dispatch(loadingFullActive(false));
      });
  };

  const apiDeleteHoliday = async () => {
    dispatch(loadingFullActive(true));

    await api
      .delete(`/holidays/${holidayId}`)
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message: response?.data?.message || "Feriado removido com sucesso!",
          })
        );
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        setOpen(false);
        getDataApiHolidays(page);
        dispatch(loadingFullActive(false));
      });
  };

  const openModalDelete = (id) => {
    setHolidayId(id);
    setOpen(true);
  };

  const handlePagination = (page) => {
    setPage(page);
    getDataApiHolidays(page);
  };

  useEffect(() => {
    getDataApiHolidays();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainTemplate title="Adicionar feriado">
        <Card>
          <Grid container spacing={2}>
            <Grid item md={3} xs={6}>
              <Label text="Data" />

              <Controller
                name="data"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <Input
                    {...field}
                    ref={ref}
                    error={errors.data ? true : false}
                    fullWidth
                    type="date"
                  />
                )}
              />

              {errors.data && <MessageError>Campo necessário</MessageError>}
            </Grid>
            <Grid item md={3} xs={6}>
              <Label text="Dia da semana" />

              <Controller
                name="diaSemana"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <SelectInput
                    {...field}
                    ref={ref}
                    error={errors.diaSemana ? true : false}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    <MenuItem value={"segunda-feira"}>Segunda-feira</MenuItem>
                    <MenuItem value={"terça-feira"}>Terça-feira</MenuItem>
                    <MenuItem value={"quarta-feira"}>Quarta-feira</MenuItem>
                    <MenuItem value={"quinta-feira"}>Quinta-feira</MenuItem>
                    <MenuItem value={"sexta-feira"}>Sexta-feira</MenuItem>
                    <MenuItem value={"sábado"}>Sábado</MenuItem>
                    <MenuItem value={"domingo"}>Domingo</MenuItem>
                  </SelectInput>
                )}
              />

              {errors.diaSemana && (
                <MessageError>Campo necessário</MessageError>
              )}
            </Grid>
          </Grid>

          <Grid mt={0} container spacing={2}>
            <Grid item md={6} xs={6}>
              <Label text="Nome do feriado" />

              <Controller
                name="feriado"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <Input
                    {...field}
                    ref={ref}
                    error={errors.feriado ? true : false}
                    fullWidth
                    placeholder="Feriado"
                  />
                )}
              />

              {errors.feriado && <MessageError>Campo necessário</MessageError>}
            </Grid>
          </Grid>

          <Button
            onClick={handleSubmit(apiCreateHoliday)}
            text="Adicionar"
            sx={{ mt: 4, mb: 1 }}
          />
        </Card>

        <TitleList>Feriados</TitleList>

        <Card style={{ padding: 0 }}>
          <Table header={headerTable} loading={loadingTable}>
            {contentTable?.length > 0 &&
              contentTable?.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item["Datas"]}</td>
                  <td>{item['"Dia da semana"']}</td>
                  <td>{item["Feriado"]}</td>
                  <td>{item.acoes}</td>
                </tr>
              ))}
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              paddingBottom: 20,
            }}
          >
            <Pagination
              count={lastPage}
              page={page}
              onChange={(event, value) => handlePagination(value)}
            />
          </div>
        </Card>

        <MainModal width={450} open={open} setOpen={setOpen}>
          <h2 style={{ margin: 0 }}>Confirmação</h2>
          <p>Deseja realmente excluir esse feriado?</p>

          <ModalButtonArea>
            <button onClick={() => apiDeleteHoliday()}>Confirmar</button>

            <button onClick={() => setOpen(false)}>Cancelar</button>
          </ModalButtonArea>
        </MainModal>
      </MainTemplate>
    </>
  );
}
