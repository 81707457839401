import styled from "styled-components";

export const Values = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);

  margin-top: 0;
  margin-bottom: 0;
`;

export const Subtitle = styled.h3`
  font-size: 15px;
  font-weight: 700;
  color: var(--text-color);

  margin-top: 0;
  margin-bottom: 0;
`;

export const Description = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: var(--text-color);

  margin-top: 4px;
  margin-bottom: 16px;
`;
