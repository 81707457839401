import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import MainModal from "../../../components/MainModal";
import api from "../../../services/api";
import { ModalButtonArea } from "./styled";
import { loadingFullActive } from "../../../store/modules/loadingFull/action";
import { snackbarActive } from "../../../store/modules/snackbar/action";

export default function ModalExcluirIndice({
  open,
  setOpen,
  apiGetDataIndices,
  valueIndiceSelected,
}) {
  const { handleSubmit } = useForm();

  const dispatch = useDispatch();

  const apiDeleteIndice = async () => {
    dispatch(loadingFullActive(true));

    await api
      .delete(`/indexes/values/${valueIndiceSelected?.id}/delete`)
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message || "O índice foi excluído com sucesso!",
          })
        );

        apiGetDataIndices();

        setOpen(false);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  return (
    <>
      <MainModal width={450} open={open} setOpen={setOpen}>
        <h2 style={{ margin: 0 }}>Confirmar</h2>

        <p style={{ paddingTop: "8px", margin: 0 }}>
          Deseja excluir esse índice? <code>{valueIndiceSelected.indice}</code>
        </p>

        <form onSubmit={handleSubmit(apiDeleteIndice)}>
          <ModalButtonArea>
            <button type="submit">Excluir índice</button>

            <button type="button" onClick={() => setOpen(false)}>
              Cancelar
            </button>
          </ModalButtonArea>
        </form>
      </MainModal>
    </>
  );
}
