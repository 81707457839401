import { Box, IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

import MainModal from "../../../../components/MainModal";
import api from "../../../../services/api";
import { snackbarActive } from "../../../../store/modules/snackbar/action";
import { loadingFullActive } from "../../../../store/modules/loadingFull/action";
import { ButtonCustom, ButtonCustomCancel, Description, Title } from "./styled";

export default function ModalDeleteAlcada({
  open,
  setOpen,
  alcada,
  apiGetAlcadas,
}) {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setOpen(false);
  };

  const apiDeleteAlcada = () => {
    dispatch(loadingFullActive(true));

    handleCloseModal();

    api
      .delete(`/alcadas/${alcada?.id}`)
      .then((response) => {
        apiGetAlcadas();

        dispatch(
          snackbarActive({
            type: "success",
            message: response?.data?.message || "Alçada removida com sucesso!",
          })
        );
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  return (
    <MainModal open={open} setOpen={setOpen} width="400px">
      <Box
        sx={{
          position: "absolute",
          top: "8px",
          right: "8px",
        }}
      >
        <IconButton title="Fechar" onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Title>Remover alçada</Title>

      <Description>
        Deseja remover alçada <span>#{alcada?.id}</span>? Obs: esta ação é
        irreversível
      </Description>

      <Box
        sx={{
          mt: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          gap: "12px",
        }}
      >
        <ButtonCustom onClick={apiDeleteAlcada}>Remover alçada</ButtonCustom>

        <ButtonCustomCancel onClick={handleCloseModal}>
          Cancelar
        </ButtonCustomCancel>
      </Box>
    </MainModal>
  );
}
