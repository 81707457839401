import { Area, Title } from "./styled";

export default function Container(props) {
  return (
    <Area>
      <Title>{props.title}</Title>
      {props.children}
    </Area>
  );
}
