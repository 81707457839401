import styled from "styled-components";

export const TableCustom = styled.table`
  width: 100%;
`;

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color);

  margin-top: 0;
  margin-bottom: 16px;
`;

export const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 1em;
  z-index: 2;

  display: flex;
  align-items: center;
  gap: 16px;
`;
